import React, { useEffect, useMemo, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { useParams } from 'react-router-dom'
import Loading from '../../../components/UI/Loading/Loading'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { connect, ConnectedProps } from 'react-redux'
import Pagination from '../../../components/UI/Pagination/Pagination'
import SearchField from '../../../components/UI/SearchField/SearchField'
import Button from '../../../components/UI/Button/Button'
import {
	IBuild,
	IBuildAPIRequest,
	IBuildCreate,
	IBuildUpdate,
	TBuildSortFields
} from '../../../interfaces/cabinet/builds'
import {
	buildGetAllHandler,
	createBuildHandler,
	deleteBuildByIdHandler,
	updateBuildByIdHandler
} from '../../../services/cabinet/builds'
import ModalEditBuild from './ModalEditBuild'
import ModalAddBuild from './ModalAddBuild'
import { IVersion } from '../../../interfaces/cabinet/versions'
import { versionGetAllHandler } from '../../../services/cabinet/versions'
import {
	DEFAULT_PAGE_SIZE,
	INFINITE_NUMBER,
	INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS,
	truncateString
} from '../../../helpers/helpers'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import ModalUpload from '../HardwareKeys/ModalUpload'
import { modalLogShowHandler } from '../../../store/actionCreators/UI/modalLog'
import { uploadLicenseHardwareKeyHandler } from '../../../services/cabinet/license'
import Select from 'react-select'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'

const Builds = ({
	getVersions,
	getBuilds,
	createBuild,
	updateBuild,
	removeBuild,
	uploadLicenseHardwareKeys,
	showModal,
	showModalLog,
	notificationAdd,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { productId } = useParams<{ productId: string }>()
	const [status, setStatus] = useState<TStatus>('loading')
	const [versions, setVersions] = useState<IVersion[]>([])
	const [table, setTable] = useState<IBuild[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterBuilds, setFilterBuilds] = useState<IBuildAPIRequest>({
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0,
		productId
	})

	const searchHandle = (value: string) => {
		setFilterBuilds(prev => ({
			...prev,
			filter: value,
			pageNo: 0
		}))
	}

	const selectVersionHandle: TOnChange = option => {
		if (option) {
			setFilterBuilds(prev => ({
				...prev,
				versionId: option.value,
				pageNo: 0
			}))
		}
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		Platform: {
			desc: true,
			classNames: ['table-sort']
		},
		BuildVersionCode: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterBuilds(prev => ({
			...prev,
			pageNo
		}))
	}

	const sortHandle = (sortField: TBuildSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterBuilds(prev => ({
			...prev,
			sortField,
			desc: updatedSortColumns[sortField].desc
		}))

		setSortColumns(updatedSortColumns)
	}

	const uploadFileHandler = async (buildId: string, file: File[]) => {
		if (file && file.length > 0) {
			const response = await uploadLicenseHardwareKeys(buildId, file)

			if (response?.status === 200) {
				if (!response.data.errorCount) {
					notificationAdd(t('pages:builds:notificationUpload'), 'info')
				} else {
					showModalLog({
						details: response.data
					})

					notificationAdd(t('pages:builds:notificationUploadError'), 'info')
				}
			}

			return response
		}
	}

	const createHandler = () => {
		showModal({
			type: 'description',
			Component: ModalAddBuild,
			ComponentProps: {
				versions,
				onConfirm: async (data: IBuildCreate, file: File[]) => {
					toggleLoader(true)

					try {
						const response = await createBuild(data)
						await uploadFileHandler(response.data.id, file)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:builds:notificationCreate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateHandler = (item: IBuild) => {
		showModal({
			type: 'description',
			Component: ModalEditBuild,
			ComponentProps: {
				item,
				versions,
				onConfirm: async (data: IBuildUpdate, file: File[]) => {
					toggleLoader(true)

					try {
						const response = await updateBuild(item.id, data)
						await uploadFileHandler(item.id, file)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:builds:notificationUpdate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},
				onRemove: async (id: string) => {
					toggleLoader(true)

					try {
						const response = await removeBuild(id)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:builds:notificationRemove'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const uploadHandler = (item: IBuild) => {
		showModal({
			type: 'middle',
			Component: ModalUpload,
			ComponentProps: {
				accept: INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS,
				onConfirm: (file: File[]) => uploadFileHandler(item.id, file)
			}
		})
	}

	useEffect(() => {
		getBuilds(filterBuilds).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				setStatus('ready')
			}
		})
	}, [getBuilds, filterBuilds])

	useEffect(() => {
		getVersions({
			desc: false,
			sortField: 'Name',
			pageNo: 0,
			pageSize: INFINITE_NUMBER
		}).then(response => {
			if (response?.status === 200) {
				setVersions(response.data.data)
			}
		})
	}, [getVersions])

	const productTitle = useMemo(() => {
		return productId ? `${versions?.[0]?.product.name || ''} ${versions?.[0]?.name || ''}` : ''
	}, [productId, versions])

	if (status === 'loading') {
		return <Loading />
	}

	return (
		<>
			<Pagetitle title={`${t('pages:builds:title')} ${productTitle}`} />

			<div className="filters">
				<div className="filters-item filters-item--search">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:builds:searchField')} />
				</div>

				{productId ? (
					<div className="filters-item filters-item--select">
						<Select
							name={'version'}
							onChange={selectVersionHandle}
							options={[
								{
									label: t('common:selectDefaultValue'),
									value: ''
								},
								...versions
									.filter(v => v.product.id === productId)
									.map(item => ({
										label: item.name,
										value: item.id
									}))
							]}
							defaultValue={{
								label: t('common:selectDefaultValue'),
								value: ''
							}}
							placeholder={t('pages:builds:versions')}
							{...selectProps}
						/>
					</div>
				) : null}

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} className="button--large" onClick={createHandler}>
							{t('pages:builds:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col width="17%" />
								<col />
								<col width="17%" />
								<col width="22.1%" />
								<col width="15.3%" />
								<col width="9.4%" />
							</colgroup>
							<thead>
								<tr>
									<th onClick={() => sortHandle('Platform')}>
										<div className={sortColumns.Platform.classNames.join(' ')}>
											{t('pages:builds:tableHeadingPlatform')}
										</div>
									</th>
									<th>
										<div>{t('pages:builds:tableHeadingProduct')}</div>
									</th>
									<th onClick={() => sortHandle('BuildVersionCode')}>
										<div className={sortColumns.BuildVersionCode.classNames.join(' ')}>
											{t('pages:builds:tableHeadingCode')}
										</div>
									</th>
									<th>
										<div>{t('pages:builds:tableHeadingDescription')}</div>
									</th>
									<th>
										<div>{t('pages:builds:tableHeadingHardware')}</div>
									</th>
									<th>
										<div>{t('pages:builds:tableHeadingLink')}</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button className="button-link" type="button" onClick={() => updateHandler(item)}>
													{item.platform}
												</button>
											</td>
											<td>{item.product?.name || '-'}</td>
											<td>
												<div className="table-break">{item.buildVersionCode}</div>
											</td>
											<td>{truncateString(item.description)}</td>
											<td>
												<div className="text-center">
													<button className="button-link" onClick={() => uploadHandler(item)}>
														{item.hardwareLicenseFiles || 0}
													</button>
												</div>
											</td>
											<td>
												{item.downloadLink && (
													<div className="table-chain text-center">
														<a href={item.downloadLink}>
															<svg xmlns="http://www.w3.org/2000/svg" width="19.995" height="20">
																<path d="M8.564 12.475a1.037 1.037 0 01-.735-.3 4.886 4.886 0 010-6.9l3.84-3.84a4.88 4.88 0 116.9 6.9l-1.755 1.755a1.044 1.044 0 01-1.472-1.48l1.755-1.755a2.8 2.8 0 00-3.96-3.96l-3.84 3.84a2.8 2.8 0 000 3.96 1.04 1.04 0 01-.735 1.775zM4.877 20a4.88 4.88 0 01-3.451-8.331L3.182 9.91a1.04 1.04 0 011.471 1.471L2.897 13.14a2.8 2.8 0 003.96 3.96l3.84-3.84a2.8 2.8 0 000-3.96 1.04 1.04 0 111.467-1.471 4.886 4.886 0 010 6.9l-3.84 3.84A4.848 4.848 0 014.877 20z" />
															</svg>
														</a>
													</div>
												)}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getVersions: versionGetAllHandler,
	getBuilds: buildGetAllHandler,
	createBuild: createBuildHandler,
	updateBuild: updateBuildByIdHandler,
	removeBuild: deleteBuildByIdHandler,
	uploadLicenseHardwareKeys: uploadLicenseHardwareKeyHandler,

	showModal: modalShowHandler,
	showModalLog: modalLogShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Builds)
