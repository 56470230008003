import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { IBuild, IBuildAPIRequest, IBuildCreate, IBuildUpdate } from '../../interfaces/cabinet/builds'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function buildGetAllHandler(
	params: IBuildAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IBuild[]>> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<APIResponse<IBuild[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/build`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createBuildHandler(data: IBuildCreate, errorOptions = serviceHandlerErrorOptions): AppThunk<IBuild> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IBuild>({
				method: 'POST',
				url: `${API_CABINET_PATH}/build`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateBuildByIdHandler(
	id: string,
	data: IBuildUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBuild> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IBuild>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/build/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function deleteBuildByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			return await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/build/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getBuildsByVersionHandler(
	versionId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBuild[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IBuild[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/build/version/${versionId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
