import { APIResponse, AppThunk } from '../../interfaces/helpers'
import axios from 'axios'
import { API_SUPPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	ISupportRequestEmployee,
	ISupportRequestEmployeeAPIRequest,
	ISupportRequestEmployeeAPIResponse,
	ISupportRequestEmployeeCreate,
	ISupportRequestEmployeeListOfUserRequestsForOrgAdminAPIRequest,
	ISupportRequestEmployeeUpdate
} from '../../interfaces/support/supportRequestEmployee'
import { serviceHandlerErrorOptions } from '../helpers'
import {
	ISupportAttach,
	ISupportAttachCreate,
	ISupportComment,
	ISupportCommentCreate,
	ISupportCommentUpdate,
	TSupportStates
} from '../../interfaces/support/common'

export function supportRequestEmployeeGetAllHandler(
	params: ISupportRequestEmployeeAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ISupportRequestEmployeeAPIResponse[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<ISupportRequestEmployeeAPIResponse[]>>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/listFiltered`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function supportRequestEmployeeGetAllListOfAnyUserRequestsForOrgAdminHandler(
	params: ISupportRequestEmployeeListOfUserRequestsForOrgAdminAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ISupportRequestEmployeeAPIResponse[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<ISupportRequestEmployeeAPIResponse[]>>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/listOfAnyUserRequestsForOrgAdmin`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function supportRequestEmployeeGetAllListOfOrgUserRequestsForOrgAdminHandler(
	params: ISupportRequestEmployeeListOfUserRequestsForOrgAdminAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ISupportRequestEmployeeAPIResponse[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<ISupportRequestEmployeeAPIResponse[]>>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/listOfOrgUserRequestsForOrgAdmin`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createSupportRequestEmployeeHandler(
	data: ISupportRequestEmployeeCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportRequestEmployee> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportRequestEmployee>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function updateSupportRequestEmployeeByIdHandler(
	topicId: string,
	data: ISupportRequestEmployeeUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportRequestEmployee> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportRequestEmployee>({
				method: 'PUT',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${topicId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteSupportRequestEmployeeByIdHandler(
	topicId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${topicId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function getSupportRequestEmployeeByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportRequestEmployee> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportRequestEmployee>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createSupportRequestEmployeeCommentHandler(
	ticketId: string,
	data: ISupportCommentCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportComment> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportComment>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${ticketId}/comment`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function updateSupportRequestEmployeeCommentByIdHandler(
	ticketId: string,
	commentId: string,
	data: ISupportCommentUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportComment> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportComment>({
				method: 'PUT',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${ticketId}/comment/${commentId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId,
					commentId
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteSupportRequestEmployeeCommentByIdHandler(
	ticketId: string,
	commentId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${ticketId}/comment/${commentId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId,
					commentId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createSupportRequestEmployeeAttachHandler(
	ticketId: string,
	data: ISupportAttachCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportAttach> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportAttach>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${ticketId}/attach`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function getSupportRequestEmployeeAttachByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<Blob> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<Blob>({
				method: 'GET',
				responseType: 'blob',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/attach/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function updateSupportRequestEmployeeStateByIdHandler(
	ticketId: string,
	state: TSupportStates,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<string> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<string>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestEmployee/${ticketId}/change_state`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId
				},
				data: state
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}
