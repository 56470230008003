import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { getUserLicensesHandler } from '../../../services/cabinet/license'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import ModalLicenseDetails from './ModalLicenseDetails'
import { ISelfLicense } from '../../../interfaces/cabinet/license'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { EPlatforms, getEmail, isRuRegion, STORE_LINKS } from '../../../helpers/helpers'
import { TStatus } from '../../../interfaces/helpers'
import Loading from '../../../components/UI/Loading/Loading'
import { unlinkLicenseHandler } from '../../../services/license/license'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { userActivationHandler } from '../../../services/passport/user'
import { RootState } from '../../../store/reducers/rootReducer'
import { IUserActivationRequest } from '../../../interfaces/passport/user'
import ModalLicenseFutureDetails from './ModalLicenseFutureDetails'
import ModalLicenseUnlink from '../../../components/Modals/ModalLicenseUnlink'
import { LicenseCard } from './LicenseCard'
import groupBy from 'lodash/groupBy'

const Home: FC<ConnectedProps<typeof connector>> = ({
	user,
	activateCode,
	getLicenses,
	unlinkLicense,
	showModal,
	showModalConfirm,
	toggleLoader,
	notificationAdd
}) => {
	const { t, i18n } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [licenses, setLicenses] = useState<ISelfLicense[] | undefined>()

	const fetchData = useCallback(async () => {
		const response = await getLicenses()

		if (response?.status === 200) {
			setLicenses(response.data)
			setStatus('ready')
		}
	}, [getLicenses])

	const storeLinks = useMemo(() => {
		const defaultLinks = Object.entries(STORE_LINKS[i18n.languages[0].toLowerCase() as 'ru' | 'en'])

		const licenseLinks =
			licenses?.reduce((acc: string[][], license) => {
				const accLinks = acc.map(([, link]) => link)

				const links =
					license.builds
						?.filter(
							l =>
								![EPlatforms.Android, EPlatforms.iOS, EPlatforms.Linux_x86_64, EPlatforms.MacOS].includes(l.platform) &&
								!accLinks.includes(l.downloadLink)
						)
						?.map(l => [l.platform, l.downloadLink]) || []

				return [...acc, ...links]
			}, []) || []

		const combinedLinks = [...licenseLinks, ...defaultLinks]

		if (isRuRegion()) {
			return combinedLinks
		}

		return combinedLinks.filter(([platform]) => platform === EPlatforms.Windows_x64)
	}, [i18n.languages, licenses])

	const licensesByType = useMemo(() => {
		const B2CAccountIds = user?.accounts?.filter(a => a.accountType === 'B2C').map(a => a.accountId) || []
		const B2BAccountIds = user?.accounts?.filter(a => a.accountType === 'B2B').map(a => a.accountId) || []
		const B2GAccountIds = user?.accounts?.filter(a => a.accountType === 'B2G').map(a => a.accountId) || []

		const B2CLicences = licenses?.filter(l => B2CAccountIds.includes(l.accountId)) || []
		const B2BLicences = licenses?.filter(l => B2BAccountIds.includes(l.accountId)) || []
		const B2GLicences = licenses?.filter(l => B2GAccountIds.includes(l.accountId)) || []

		return {
			b2c: B2CLicences,
			b2b: B2BLicences,
			b2g: B2GLicences
		}
	}, [licenses, user])

	const licencesByOrganization = useMemo(() => {
		const groupedByAccountId = groupBy([...licensesByType.b2b, ...licensesByType.b2g], 'accountId')

		const licencesList: Array<{
			organizationName: string
			licenses: ISelfLicense[]
		}> = []

		Object.entries(groupedByAccountId).forEach(([accountId, list]) => {
			const org = user?.accounts?.find(a => a.accountId === accountId)?.organization
			const organizationName = org?.name || org?.fullName || '-'

			licencesList.push({
				organizationName,
				licenses: list
			})
		})

		return licencesList
	}, [licensesByType.b2b, licensesByType.b2g, user])

	const unlinkHardwareLicense = async (item: ISelfLicense) => {
		toggleLoader(true)

		try {
			const response = await unlinkLicense(item.activationCode)

			if (response?.status === 200) {
				if (response?.data?.result === 'SUCCESS' || response?.data?.result === 'NO_ATTEMPTS_LEFT') {
					showModal({
						type: 'large',
						Component: ModalLicenseUnlink,
						ComponentProps: {
							details: response.data
						}
					})
				}

				await fetchData()

				notificationAdd(t('pages:home:notificationUnlinkHardware'), 'info')
			}

			return response
		} finally {
			toggleLoader(false)
		}
	}

	const openModalHandler = (item: ISelfLicense) => {
		showModal({
			type: 'large',
			Component: ModalLicenseDetails,
			ComponentProps: {
				item,
				onUnlinkHardware: unlinkHardwareLicense
			}
		})
	}

	const openModalDetailsHandler = (item: ISelfLicense) => {
		showModal({
			type: 'large',
			Component: ModalLicenseFutureDetails,
			ComponentProps: {
				item
			}
		})
	}

	const unlinkHardwareHandler = async (item: ISelfLicense) => {
		showModalConfirm({
			type: 'custom',
			buttonText: t('common:actionOk'),
			questionText: t('pages:home:modalUnlinkText'),
			onConfirm: async () => {
				return await unlinkHardwareLicense(item)
			}
		})
	}

	const activationCodeHandler = async (item: ISelfLicense) => {
		const account = user.accounts?.find(a => a.accountId === item.accountId)

		if (!account) {
			return
		}

		showModalConfirm({
			type: 'custom',
			questionText: t('pages:home:modalActivateCodeText', {
				value: item.productName
			}),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const data: IUserActivationRequest = {
						role: user.role,
						firstName: user.firstName,
						lastName: user.lastName,
						patronymic: user.patronymic,
						email: user.email,
						phone: user.phone,
						activationCode: item.activationCode
					}

					const response = await activateCode(data)

					if (response.status === 200) {
						await fetchData()

						notificationAdd(t('pages:home:notificationActivateCode'), 'info')
					}
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	useEffect(() => {
		fetchData()
	}, [fetchData])

	if (status === 'loading') return <Loading />

	return (
		<Fragment>
			<section className="user-license">
				{licenses && licenses.length === 0 ? (
					<Fragment>
						<h2>{t('pages:home:title')}</h2>

						<div className="user-license-empty">
							<div>
								<div className="user-license-empty__title">{t('pages:home:emptyTitle')}</div>
								<div className="user-license-empty__description">{t('pages:home:emptyDescription')}</div>
							</div>
						</div>
					</Fragment>
				) : null}

				{licenses && licenses.length > 0 ? (
					<Fragment>
						{licensesByType.b2c.length > 0 ? (
							<Fragment>
								<h2>{t('pages:home:titleMyLicences')}</h2>

								<div className="user-license-cards">
									{licensesByType.b2c.map((license, idx) => (
										<div key={`${license.versionName}_${idx}`}>
											<LicenseCard
												license={license}
												onOpenModal={openModalHandler}
												onOpenModalDetails={openModalDetailsHandler}
												onActivationCode={activationCodeHandler}
												onUnlinkHardware={unlinkHardwareHandler}
											/>
										</div>
									))}
								</div>

								<div className="hr" />
							</Fragment>
						) : null}

						{licencesByOrganization.length > 0 ? (
							<Fragment>
								{licencesByOrganization.map(item => {
									return (
										<Fragment key={item.organizationName}>
											<h2>
												{t('pages:home:titleOrgLicences')}: {item.organizationName}
											</h2>

											<div className="user-license-cards">
												{item.licenses.map((license, idx) => (
													<div key={`${license.versionName}_${idx}`}>
														<LicenseCard
															license={license}
															onOpenModal={openModalHandler}
															onOpenModalDetails={openModalDetailsHandler}
															onActivationCode={activationCodeHandler}
															onUnlinkHardware={unlinkHardwareHandler}
														/>
													</div>
												))}
											</div>

											<div className="hr" />
										</Fragment>
									)
								})}
							</Fragment>
						) : null}

						<div className="user-license-apps">
							<div className="user-license-apps__title">{t('pages:home:appsTitle')}</div>

							<div className="user-license-apps__list">
								{storeLinks.map(([platform, link], index) => (
									<Fragment key={`${platform}_${index}`}>
										{link ? (
											<a className="user-license-app" href={link} target="_blank" rel="noopener noreferrer">
												<div className="user-license-app__icon">
													<img src={`/assets/img/platforms/${platform}.svg`} alt="" />
												</div>
												<div className="user-license-app__title">{platform}</div>
											</a>
										) : null}
									</Fragment>
								))}
							</div>
						</div>
					</Fragment>
				) : null}
			</section>

			<section className="user-help">
				<div className="user-help-item">
					<div className="user-help-text">
						<div className="user-help-title">{t('pages:home:help1Title')}</div>
						<p>{t('pages:home:help1Text')}</p>
					</div>
					<Link className="button" to={'/support'}>
						{t('pages:home:help1Button')}
					</Link>
				</div>

				<div className="user-help-item">
					<div className="user-help-text">
						<div className="user-help-title">{t('pages:home:help2Title')}</div>
						<p>{t('pages:home:help2Text')}</p>
					</div>
					<a
						className="button"
						href={`mailto:${getEmail().emailSupport}`}
						dangerouslySetInnerHTML={{
							__html: t('pages:home:help2Button', {
								email: getEmail().emailSupport
							})
						}}
					/>
				</div>
			</section>
		</Fragment>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const mapDispatch = {
	activateCode: userActivationHandler,
	getLicenses: getUserLicensesHandler,
	unlinkLicense: unlinkLicenseHandler,
	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	toggleLoader: toggleLoaderHandler,
	notificationAdd: notificationAddHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Home)
