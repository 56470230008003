import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../interfaces/helpers'
import Loading from '../../../components/UI/Loading/Loading'
import { getLocalizationFileContent, getLocalizationFilePath, replaceLinks } from '../../../helpers/helpers'

interface IContent {
	title: string
	questions: Array<{
		number: string
		question: string
		answer: string
	}>
}

const Faq = () => {
	const { i18n } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [content, setContent] = useState<IContent>({
		title: '',
		questions: []
	})

	useEffect(() => {
		getLocalizationFileContent(getLocalizationFilePath().faq, i18n.languages[0])
			.then(response => {
				if (response?.status === 200) {
					setContent(response.data)
				}
			})
			.finally(() => {
				setStatus('ready')
			})
	}, [i18n.languages])

	if (status === 'loading') return <Loading />

	return (
		<>
			<h1 className="h4 text-center">
				<strong dangerouslySetInnerHTML={{ __html: content.title }} />
			</h1>

			{content.questions.length > 0 &&
				content.questions.map((item, index) => {
					return (
						<Fragment key={index}>
							<p>
								<strong>
									{item.number}. <span dangerouslySetInnerHTML={{ __html: replaceLinks(item.question) }} />
								</strong>
							</p>
							<p dangerouslySetInnerHTML={{ __html: replaceLinks(item.answer) }} />
							<p>&nbsp;</p>
						</Fragment>
					)
				})}
		</>
	)
}

export default Faq
