import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import Select from 'react-select'
import { orderBy } from 'lodash'
import omit from 'lodash/omit'
import dayjs from 'dayjs'
import { TStatus } from '../../../interfaces/helpers'
import { IUser, IUserAccount, IUserCreate, IUserEditRequest, IUserUpdate } from '../../../interfaces/passport/user'
import { IFaculty, IFacultyGroup, IGrade } from '../../../interfaces/passport/organizations'
import { ILicensePackage, ILicensePackageGroupList } from '../../../interfaces/cabinet/licensePackage'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import {
	attachUserToOrganizationHandler,
	createUserHandler,
	getUserDataByIdHandler,
	getUserDataByMailHandler,
	resendActivationEmailHandler,
	updateUserDataByIdHandler
} from '../../../services/passport/user'
import { BLOCKED, USER } from '../../../helpers/roles'
import {
	createActivationCodeByLicenseGroupIdsHandler,
	deleteActivationCodeByIdHandler,
	getActivationCodeByUserIdHandler
} from '../../../services/cabinet/activationCode'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import {
	getFacultyByIdHandler,
	getFacultyGroupsByIdHandler,
	getGradesByIdHandler
} from '../../../services/passport/organization'
import { IActivationCodeListResponse } from '../../../interfaces/cabinet/activationCode'
import { getActiveLicensePackageWithGroupsHandler } from '../../../services/cabinet/licensePackage'
import ModalChangePassword from '../../../components/User/ModalChangePassword'
import { initResetPasswordByAdminHandler } from '../../../services/passport/recovery'
import { unlinkLicenseHandler } from '../../../services/license/license'
import ModalLicenseUnlink from '../../../components/Modals/ModalLicenseUnlink'
import { formatUsername, REGEX_EMAIL, REGEX_PHONE, REGEX_V4 } from '../../../helpers/helpers'
import Loading from '../../../components/UI/Loading/Loading'
import Error from '../../Error/Error'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Input from '../../../components/UI/Input/Input'
import { selectProps } from '../../../components/UI/Select/helpers'
import Button from '../../../components/UI/Button/Button'
import LicenseItem from '../../../components/User/LicenseItem'
import LicenseItemAdd from '../../../components/User/LicenseItemAdd'
import ModalUserExists from './ModalUserExists'

interface IFormInputs {
	lastName: string
	firstName: string
	patronymic: string
	email: string
	phone: string
	student: string
	faculty: string
	grade: string
	facultyGroup: string
	licenseGroup: string
}

interface IUserEditorProps {
	organizationId: string
	accountId: string
	userId?: string
	parentRoute?: string
	crumbs?: Array<{
		to: string
		title: string
		onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	}>
}

const UserEditor = ({
	organizationId,
	accountId,
	userId,
	parentRoute,
	crumbs,
	getUser,
	getUserByMail,
	getFaculties,
	getGroups,
	getGrades,
	getActivationCodes,
	loadActiveLicenseGroups,
	createUser,
	updateUser,
	attachUser,
	createActivationCodes,
	removeActivationCode,
	resetPassword,
	unlinkLicense,
	resendActivationEmail,
	toggleLoader,
	showModal,
	showModalConfirm,
	notificationAdd
}: IUserEditorProps & ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [currentUser, setCurrentUser] = useState<IUser>()
	const [currentAccount, setCurrentAccount] = useState<IUserAccount>()
	const { register, errors, handleSubmit, watch, formState, setValue, control } = useForm<IFormInputs>({
		defaultValues: {}
	})

	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)
	const isStudent = !!+watch('student')
	const [faculties, setFaculties] = useState<IFaculty[]>([])
	const [facultiesGroups, setFacultiesGroups] = useState<IFacultyGroup[]>([])
	const [grades, setGrades] = useState<IGrade[]>([])
	const history = useHistory()

	const [activationCodes, setActivationCodes] = useState<IActivationCodeListResponse[]>([])
	const [licensePackages, setLicensePackages] = useState<ILicensePackage[]>([])

	const [licenseGroupsStudent, setLicenseGroupsStudent] = useState<ILicensePackageGroupList[]>([])
	const [selectedLicenseGroupsStudent, setSelectedLicenseGroupsStudent] = useState<ILicensePackageGroupList[]>([])
	const [licenseGroupsTeacher, setLicenseGroupsTeacher] = useState<ILicensePackageGroupList[]>([])
	const [selectedLicenseGroupsTeacher, setSelectedLicenseGroupsTeacher] = useState<ILicensePackageGroupList[]>([])
	const [selectedLicenseGroupsIds, setSelectedLicenseGroupsIds] = useState<Array<string>>([])

	const [licensePackagesStatus, setLicensePackagesStatus] = useState<'loading' | 'none' | 'empty' | 'available'>(
		'loading'
	)

	//TODO: check
	const selectFacultyGroupRef = useRef<any>(null)
	const selectLicenseGroupRef = useRef<any>(null)

	const getCurrentAccount = (user: IUser, organizationId: string) => {
		return user ? user.accounts?.find(ac => ac.organization.id === organizationId) : undefined
	}

	const checkIsStudent = useCallback(
		() => (!userId && isStudent) || (!!userId && !!currentAccount?.student),
		[isStudent, currentAccount, userId]
	)

	const onSubmit = async (data: IFormInputs) => {
		const newUser: IUserEditRequest = {
			...omit(data, 'licenseGroup'),
			role: USER,
			accounts: [
				{
					organization: organizationId,
					student: checkIsStudent(),
					faculty: checkIsStudent() ? data.faculty : '',
					grade: checkIsStudent() ? data.grade : '',
					facultyGroup: checkIsStudent() ? data.facultyGroup : ''
				}
			]
		}

		if (!userId) {
			toggleLoader(true)
			setIsSubmitting(true)
			let existsUser: IUser | null = null
			try {
				const responseExists = await getUserByMail(data.email, { show: false })
				existsUser = responseExists.status === 200 ? responseExists.data : null
			} finally {
				setIsSubmitting(false)
				toggleLoader(false)
			}
			if (existsUser) {
				onUserExists(existsUser, newUser)
			} else {
				saveUser(newUser)
			}
		} else {
			saveUser(newUser)
		}
	}

	const onUserExists = (user: IUser, newUser: IUserCreate) => {
		showModal({
			type: 'middle',
			Component: ModalUserExists,
			ComponentProps: {
				user,
				onConfirm: () => {
					saveUser(newUser, user.id)
				}
			}
		})
	}

	const saveUser = async (newUser: IUserCreate, attachUserId?: string) => {
		toggleLoader(true)
		setIsSubmitting(true)
		try {
			const responseUser = userId
				? await updateUser(userId, newUser)
				: !!attachUserId && newUser.accounts && newUser.accounts.length > 0
				? await attachUser(attachUserId, newUser.accounts[0])
				: await createUser(newUser)

			if (responseUser?.status === 200) {
				setCurrentUser(responseUser.data)
				setCurrentAccount(getCurrentAccount(responseUser.data, organizationId))

				const selectedGroupIds: string[] = checkIsStudent()
					? selectedLicenseGroupsStudent.length > 0
						? [selectedLicenseGroupsStudent[0].id]
						: []
					: selectedLicenseGroupsTeacher.map(group => group.id)

				if (selectedGroupIds.length > 0) {
					const responseCodes = await createActivationCodes(selectedGroupIds, responseUser.data.id)
					const errors = (checkIsStudent() ? selectedLicenseGroupsStudent : selectedLicenseGroupsTeacher).filter(
						item => !responseCodes.data.find(res => res.licenseGroupId === item.id)
					)
					if (errors.length > 0) {
						errors.forEach(item => {
							notificationAdd(`Error creating license ${item.versionName} (${item.id})`, 'warning')
						})
					}
				}
				notificationAdd(t(userId ? 'pages:users:notificationUpdate' : 'pages:users:notificationCreate'), 'info')
				history.replace(`${parentRoute || ''}/users`)
			}
		} finally {
			setIsSubmitting(false)
			toggleLoader(false)
		}
	}

	const cancelHandle = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (isDirty) {
			e.preventDefault()

			showModalConfirm({
				type: 'cancel',
				onConfirm() {
					history.push(`${parentRoute || ''}/users`)
				}
			})
		}
	}

	const changeFacultyHandle = useCallback(
		(value: string) => {
			setFacultiesGroups([])
			if (selectFacultyGroupRef.current) selectFacultyGroupRef.current.select.clearValue()
			setValue('facultyGroup', '')

			getGroups(organizationId, value).then(response => {
				if (response?.status === 200) {
					setFacultiesGroups(orderBy(response.data, ['name'], ['asc']))
				}
			})
		},
		[getGroups, organizationId, setValue]
	)

	const updateActivationCodes = useCallback(async () => {
		if (!userId) return

		const response = await getActivationCodes({ userId, accountId: currentAccount?.accountId })

		if (response?.status === 200) {
			setActivationCodes(response.data)
		}
	}, [getActivationCodes, userId, currentAccount])

	const updateLicensePackages = useCallback(async () => {
		if (!accountId) {
			setStatus('error')
			return
		}
		loadActiveLicenseGroups(accountId).then(response => {
			if (response.status !== 200) {
				return
			}
			setLicensePackages(response.data)
		})
	}, [loadActiveLicenseGroups, accountId])

	const passwordResetHandle = () => {
		if (currentUser?.status === BLOCKED) {
			showModalConfirm({
				type: 'error',
				questionText: t('pages:users:modalPasswordResetTextError'),
				onConfirm: () => null
			})
		} else {
			showModalConfirm({
				type: 'custom',
				questionText: t('pages:users:modalPasswordResetText'),
				onConfirm: async () => {
					if (currentUser) {
						toggleLoader(true)

						try {
							const response = await resetPassword(currentUser.email)

							if (response?.status === 200) {
								notificationAdd(t('pages:users:notificationPasswordReset'), 'info')
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		}
	}

	const passwordChangeHandle = () => {
		showModal({
			Component: ModalChangePassword,
			ComponentProps: {
				currentUser,
				onConfirm: async (data: IUserUpdate) => {
					if (!userId) return
					toggleLoader(true)

					try {
						return await updateUser(userId, data)
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const removeActivationCodeHandle = (item: IActivationCodeListResponse) => {
		showModalConfirm({
			type: 'custom',
			buttonText: t('common:actionOk'),
			questionText: t('pages:users:modalRemoveActivationCodeText'),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await removeActivationCode(item.id)

					if (response?.status === 200) {
						await updateActivationCodes()
						await updateLicensePackages()

						notificationAdd(t('pages:users:notificationRemoveActivationCode'), 'info')
					}

					return response
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	const addLicenseGroupHandle = () => {
		const licenseGroup = watch('licenseGroup')

		if (!!licenseGroup && licenseGroup.length > 0) {
			if (checkIsStudent()) {
				setSelectedLicenseGroupsStudent([
					...selectedLicenseGroupsStudent,
					licenseGroupsStudent.filter(item => item.id === licenseGroup)[0]
				])
			}

			if (!checkIsStudent()) {
				setSelectedLicenseGroupsTeacher([
					...selectedLicenseGroupsTeacher,
					licenseGroupsTeacher.filter(item => item.id === licenseGroup)[0]
				])
			}

			setSelectedLicenseGroupsIds([...selectedLicenseGroupsIds, licenseGroup])

			setValue('licenseGroup', '')
			selectLicenseGroupRef.current.select.clearValue()
		}
	}

	const removeLicenseGroupHandle = (id: string) => {
		if (checkIsStudent()) {
			setSelectedLicenseGroupsStudent(selectedLicenseGroupsStudent.filter(item => item.id !== id) || [])
		}

		if (!checkIsStudent()) {
			setSelectedLicenseGroupsTeacher(selectedLicenseGroupsTeacher.filter(item => item.id !== id) || [])
		}

		setSelectedLicenseGroupsIds(selectedLicenseGroupsIds.filter(item => item !== id) || [])
	}

	const reactivateActivationCodeHandle = (item: IActivationCodeListResponse) => {
		showModalConfirm({
			type: 'custom',
			buttonText: t('common:actionOk'),
			questionText: t('pages:users:modalReactivateActivationCodeText'),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await resendActivationEmail(item.code)

					if (response?.status === 200) {
						notificationAdd(t('pages:users:notificationReactivateActivationCode'), 'info')
					}
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	const unlinkHardwareHandle = (item: IActivationCodeListResponse) => {
		showModalConfirm({
			type: 'custom',
			buttonText: t('common:actionOk'),
			questionText: t('pages:users:modalUnlinkHardwareText', {
				value: item.hardwareMetaData
			}),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await unlinkLicense(item.code)

					if (response?.status === 200) {
						if (response?.data?.result === 'NO_ATTEMPTS_LEFT') {
							showModal({
								type: 'large',
								Component: ModalLicenseUnlink,
								ComponentProps: {
									details: response.data
								}
							})
						}

						await updateActivationCodes()

						notificationAdd(t('pages:users:notificationUnlinkHardware'), 'info')
					}

					return response
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	useEffect(() => {
		if (!userId) return

		if (!userId.match(new RegExp(REGEX_V4)) || !organizationId) {
			setStatus('error')
		} else {
			getUser(userId).then(response => {
				if (response?.status === 200) {
					setCurrentUser(response.data)
					setCurrentAccount(getCurrentAccount(response.data, organizationId))
				} else {
					setStatus('error')
				}
			})
		}
	}, [getUser, userId, organizationId])

	useEffect(() => {
		if (organizationId) {
			getFaculties(organizationId).then(response => {
				if (response?.status === 200) {
					setFaculties(orderBy(response.data, ['name'], ['asc']))

					if (currentAccount?.faculty?.id) {
						changeFacultyHandle(currentAccount.faculty?.id)
					}
				}
			})

			getGrades(organizationId).then(response => {
				if (response?.status === 200) {
					setGrades(orderBy(response.data, ['name'], ['asc']))
				}
			})
		}
	}, [organizationId, changeFacultyHandle, getFaculties, getGrades, setValue, currentAccount, getGroups])

	useEffect(() => {
		if (currentAccount) {
			updateActivationCodes()
		}
	}, [updateActivationCodes, currentAccount])

	useEffect(() => {
		if (accountId) {
			updateLicensePackages()
		}
	}, [accountId, updateLicensePackages])

	useEffect(() => {
		if (!licensePackages.length) return

		const licenseGroupsData = licensePackages.reduce((acc: Array<ILicensePackageGroupList>, licensePackage) => {
			licensePackage.licenseGroupList
				.filter(item => item.count > item.generatedActivationCodesCount)
				.filter(item => !item.dateTo || dayjs(item.dateTo).toDate() >= dayjs().toDate())
				.forEach(item => {
					acc.push(item)
				})

			return acc
		}, [])

		setLicenseGroupsStudent(licenseGroupsData.filter(item => item.type === 'student'))
		setLicenseGroupsTeacher(licenseGroupsData.filter(item => item.type === 'teacher' || item.type === 'student'))
	}, [licensePackages])

	useEffect(() => {
		if (!licensePackages.length) return

		if (checkIsStudent()) {
			const count = licenseGroupsStudent.filter(item => item.generatedActivationCodesCount <= item.count).length

			if (count > 0) {
				setLicensePackagesStatus('available')
			} else {
				setLicensePackagesStatus('empty')
			}
		}

		if (!checkIsStudent()) {
			const count = licenseGroupsTeacher.filter(item => item.generatedActivationCodesCount <= item.count).length

			if (count > 0) {
				setLicensePackagesStatus('available')
			} else {
				setLicensePackagesStatus('empty')
			}
		}
	}, [checkIsStudent, licenseGroupsStudent, licenseGroupsTeacher, licensePackages])

	useEffect(() => {
		setValue('licenseGroup', '')
		selectLicenseGroupRef.current?.select.clearValue()
	}, [setValue])

	useEffect(() => {
		if (currentAccount) {
			if (currentAccount.student) {
				if (grades.length && faculties.length && facultiesGroups.length) {
					setValue('student', '1')
					setStatus('ready')
				}
			} else {
				setStatus('ready')
			}
		} else if (accountId && !userId) {
			setStatus('ready')
		}
	}, [currentAccount, accountId, userId, grades, faculties, facultiesGroups, setValue])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle
				title={
					userId
						? formatUsername({
								firstName: currentUser?.firstName,
								lastName: currentUser?.lastName,
								patronymic: currentUser?.patronymic
						  })
						: t('pages:users:titleNew')
				}
				crumbs={crumbs}
			>
				{!!userId && (
					<ul className="list list--links">
						<li className="list-item">
							<button type="button" onClick={passwordResetHandle}>
								{t('pages:users:actionPasswordReset')}
							</button>
						</li>
						<li className="list-item">
							<button type="button" onClick={passwordChangeHandle}>
								{t('pages:users:actionPasswordChange')}
							</button>
						</li>
					</ul>
				)}
			</Pagetitle>

			<form className="form form--user" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
					<div className="form-col">
						<div className="form-item">
							<Input
								currentValue={watch('lastName')}
								type={'text'}
								name={'lastName'}
								placeholder={t('pages:users:fieldLastName')}
								isDisabled={isSubmitting}
								isError={!!errors.lastName}
								isRequired={true}
								reference={register({
									required: true,
									maxLength: 255
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.lastName}
								defaultValue={currentUser?.lastName}
							/>
						</div>

						<div className="form-item">
							<Input
								currentValue={watch('firstName')}
								type={'text'}
								name={'firstName'}
								placeholder={t('pages:users:fieldFirstName')}
								isDisabled={isSubmitting}
								isError={!!errors.firstName}
								isRequired={true}
								reference={register({
									required: true,
									maxLength: 255
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.firstName}
								defaultValue={currentUser?.firstName}
							/>
						</div>

						<div className="form-item">
							<Input
								currentValue={watch('patronymic')}
								type={'text'}
								name={'patronymic'}
								placeholder={t('pages:users:fieldPatronymic')}
								isDisabled={isSubmitting}
								isError={!!errors.patronymic}
								reference={register({
									maxLength: 255
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.patronymic}
								defaultValue={currentUser?.patronymic}
							/>
						</div>

						<div className="form-item">
							<Input
								currentValue={watch('email')}
								type={'email'}
								name={'email'}
								placeholder={t('pages:users:fieldEmail')}
								isDisabled={isSubmitting}
								isError={!!errors.email}
								isRequired={true}
								reference={register({
									required: true,
									maxLength: 255,
									pattern: REGEX_EMAIL
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.email}
								defaultValue={currentUser?.email}
							/>
						</div>

						<div className="form-item">
							<Input
								currentValue={watch('phone')}
								type={'tel'}
								name={'phone'}
								placeholder={t('pages:users:fieldPhone')}
								isDisabled={isSubmitting}
								isError={!!errors.phone}
								reference={register({
									maxLength: 255,
									pattern: REGEX_PHONE
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.phone}
								defaultValue={currentUser?.phone}
							/>
						</div>

						<div className="form-item">
							<label className="radio">
								<input
									type="radio"
									name="student"
									value="1"
									ref={register({
										required: true
									})}
									disabled={!!userId || isSubmitting}
									defaultChecked={currentAccount?.student}
								/>
								<span>{t('pages:users:fieldStudent')}</span>
							</label>

							<label className="radio">
								<input
									type="radio"
									name="student"
									value="0"
									ref={register({
										required: true
									})}
									disabled={!!userId || isSubmitting}
									defaultChecked={!currentAccount?.student}
								/>
								<span>{t('pages:users:fieldTeacher')}</span>
							</label>
						</div>

						<div className="form-item">
							<Controller
								render={({ onChange, name }) => (
									<Select
										name={name}
										defaultValue={
											faculties
												.filter(item => item.id === currentAccount?.faculty?.id)
												.map(item => {
													return {
														label: item.name,
														value: item.id
													}
												})[0]
										}
										options={faculties.map(item => {
											return {
												label: item.name,
												value: item.id
											}
										})}
										placeholder={t('pages:users:fieldFaculty')}
										isDisabled={isSubmitting || !checkIsStudent() || !faculties.length}
										isRequired={checkIsStudent()}
										isError={!!errors.faculty}
										errors={errors.faculty}
										onChange={option => {
											if (option) {
												onChange(option.value)
												changeFacultyHandle(option.value)
											}
										}}
										{...selectProps}
									/>
								)}
								name={'faculty'}
								control={control}
								rules={{
									required: checkIsStudent()
								}}
								defaultValue={currentAccount?.faculty?.id || 'dddd'}
							/>
						</div>

						<div className="form-item">
							<Controller
								render={({ onChange, name }) => (
									<Select
										name={name}
										defaultValue={
											facultiesGroups
												.filter(item => item.id === currentAccount?.facultyGroup?.id)
												.map(item => {
													return {
														label: item.name,
														value: item.id
													}
												})[0]
										}
										options={facultiesGroups.map(item => {
											return {
												label: item.name,
												value: item.id
											}
										})}
										placeholder={t('pages:users:fieldFacultyGroup')}
										isDisabled={isSubmitting || !checkIsStudent() || !facultiesGroups.length}
										isRequired={checkIsStudent()}
										isError={!!errors.facultyGroup}
										errors={errors.facultyGroup}
										onChange={option => option && onChange(option.value)}
										ref={selectFacultyGroupRef}
										{...selectProps}
									/>
								)}
								name={'facultyGroup'}
								control={control}
								rules={{
									required: checkIsStudent()
								}}
								defaultValue={currentAccount?.facultyGroup?.id || ''}
							/>
						</div>

						<div className="form-item">
							<Controller
								render={({ onChange, name }) => (
									<Select
										name={name}
										defaultValue={
											grades
												.filter(item => item.id === currentAccount?.grade?.id)
												.map(item => {
													return {
														label: item.name,
														value: item.id
													}
												})[0]
										}
										options={grades.map(item => {
											return {
												label: item.name,
												value: item.id
											}
										})}
										placeholder={t('pages:users:fieldGrade')}
										isDisabled={isSubmitting || !checkIsStudent() || !grades.length}
										isRequired={checkIsStudent()}
										isError={!!errors.grade}
										errors={errors.grade}
										onChange={option => option && onChange(option.value)}
										{...selectProps}
									/>
								)}
								name={'grade'}
								control={control}
								rules={{
									required: checkIsStudent()
								}}
								defaultValue={currentAccount?.grade?.id || ''}
							/>
						</div>

						<div className="form-controls">
							<Link className="button button--secondary" to={`${parentRoute || ''}/users`} onClick={cancelHandle}>
								{t('common:actionCancel')}
							</Link>

							<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
								{t(userId ? 'common:actionSave' : 'common:actionAdd')}
							</Button>
						</div>
					</div>

					<div className="form-col">
						{activationCodes.length > 0 && (
							<div className="form-license">
								<div className="form-license-label">
									<strong>{t('pages:users:licenseEditTitle')}</strong>
								</div>

								<div className="form-license-list">
									{activationCodes.map(item => {
										return (
											<LicenseItem
												key={item.code}
												item={item}
												onRemove={() => removeActivationCodeHandle(item)}
												onReactivate={() => reactivateActivationCodeHandle(item)}
												onUnlinkHardware={() => unlinkHardwareHandle(item)}
												isUserCanUnlinkTermlessLicense={true}
												userStatus={currentUser?.status}
											/>
										)
									})}
								</div>
							</div>
						)}

						<div className="form-license">
							<div className="form-license-label">
								<strong>{t('pages:users:licenseAddTitle')}</strong>
							</div>

							<div className="form-license-add">
								{licensePackagesStatus === 'available' && (
									<>
										<Controller
											render={({ onChange, name }) => (
												<Select
													name={name}
													options={
														checkIsStudent()
															? licenseGroupsStudent
																	.filter(item => !selectedLicenseGroupsIds.includes(item.id))
																	.map(item => {
																		return {
																			label: `${item.versionName}. ${item.productName} ${
																				item.termless ? `(${t('pages:users:termless')})` : ''
																			} (${item.count - item.generatedActivationCodesCount})`,
																			value: item.id
																		}
																	})
															: licenseGroupsTeacher
																	.filter(item => !selectedLicenseGroupsIds.includes(item.id))
																	.map(item => {
																		return {
																			label: `${item.versionName}. ${item.productName} ${
																				item.termless ? `(${t('pages:users:termless')})` : ''
																			} (${item.count - item.generatedActivationCodesCount})`,
																			value: item.id
																		}
																	})
													}
													placeholder={t('pages:users:fieldProductVersion')}
													isDisabled={isSubmitting}
													isError={!!errors.licenseGroup}
													errors={errors.licenseGroup}
													onChange={option => option && onChange(option.value)}
													ref={selectLicenseGroupRef}
													{...selectProps}
												/>
											)}
											name={'licenseGroup'}
											control={control}
										/>

										<Button
											type={'secondary'}
											htmlType={'button'}
											isDisabled={
												!watch('licenseGroup') || (checkIsStudent() && selectedLicenseGroupsStudent.length >= 1)
											}
											onClick={addLicenseGroupHandle}
										>
											{t('common:actionAdd')}
										</Button>

										{checkIsStudent() && selectedLicenseGroupsStudent.length === 0 && activationCodes.length === 0 && (
											<div className="form-license-empty">{t('pages:users:licenseNoText')}</div>
										)}
										{!checkIsStudent() && selectedLicenseGroupsTeacher.length === 0 && activationCodes.length === 0 && (
											<div className="form-license-empty">{t('pages:users:licenseNoText')}</div>
										)}
									</>
								)}

								{licensePackagesStatus === 'none' && (
									<div
										className="form-license-empty"
										dangerouslySetInnerHTML={{
											__html: t('pages:users:licenseNoneTextAdminCloud')
										}}
									/>
								)}

								{licensePackagesStatus === 'empty' && (
									<div
										className="form-license-empty"
										dangerouslySetInnerHTML={{
											__html: t('pages:users:licenseEmptyTextAdminCloud')
										}}
									/>
								)}

								{licensePackagesStatus === 'loading' && <div>{t('common:loading')}</div>}
							</div>

							<div className="form-license-list">
								{checkIsStudent() &&
									selectedLicenseGroupsStudent.map(item => (
										<LicenseItemAdd key={item.id} item={item} onRemove={() => removeLicenseGroupHandle(item.id)} />
									))}

								{!checkIsStudent() &&
									selectedLicenseGroupsTeacher.map(item => (
										<LicenseItemAdd key={item.id} item={item} onRemove={() => removeLicenseGroupHandle(item.id)} />
									))}
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	getUser: getUserDataByIdHandler,
	getUserByMail: getUserDataByMailHandler,

	createUser: createUserHandler,
	updateUser: updateUserDataByIdHandler,
	attachUser: attachUserToOrganizationHandler,
	createActivationCodes: createActivationCodeByLicenseGroupIdsHandler,
	removeActivationCode: deleteActivationCodeByIdHandler,
	resetPassword: initResetPasswordByAdminHandler,
	unlinkLicense: unlinkLicenseHandler,
	resendActivationEmail: resendActivationEmailHandler,

	getFaculties: getFacultyByIdHandler,
	getGrades: getGradesByIdHandler,
	getGroups: getFacultyGroupsByIdHandler,

	getActivationCodes: getActivationCodeByUserIdHandler,
	loadActiveLicenseGroups: getActiveLicensePackageWithGroupsHandler,

	toggleLoader: toggleLoaderHandler,
	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler
}

const connector = connect(null, mapDispatch)

export default connector(UserEditor)
