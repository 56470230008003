import {
	IContact,
	IContactCreate,
	IContactUpdate,
	ICountry,
	IFaculty,
	IFacultyGroup,
	IGrade,
	IMainContact,
	IMainContactUpdate,
	IName,
	IOrganization,
	IOrganizationAPIRequest,
	IOrganizationCreate,
	IOrganizationUpdate
} from '../../interfaces/passport/organizations'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import axios from 'axios'
import { API_PASSPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	createOrganizationContactById,
	deleteOrganizationContactById,
	getOrganizationById,
	updateOrganizationById,
	updateOrganizationContactById
} from '../../store/actionCreators/passport/organization'
import { serviceHandlerErrorOptions } from '../helpers'

export function getAllOrganizationsHandler(
	params: IOrganizationAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IOrganization[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IOrganization[]>>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function createOrganizationHandler(
	data: IOrganizationCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IOrganization> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IOrganization>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getOrganizationByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IOrganization> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IOrganization>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
			dispatch(getOrganizationById(response.data))
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function archiveOrganizationByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/archive`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteOrganizationByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function updateOrganizationByIdHandler(
	organizationId: string,
	data: IOrganizationUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IOrganization> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IOrganization>({
				method: 'PUT',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				},
				data
			})
			dispatch(updateOrganizationById(response.data))
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function createOrganizationContactByIdHandler(
	organizationId: string,
	contact: IContactCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IContact> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IContact>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/contacts`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				},
				data: contact
			})
			dispatch(createOrganizationContactById(response.data))
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function updateOrganizationContactByIdHandler(
	organizationId: string,
	contact: IMainContactUpdate | IContactUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IMainContact | IContact> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IMainContact | IContact>({
				method: 'PUT',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/contacts/${contact.id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					contactId: contact.id
				},
				data: contact
			})
			dispatch(updateOrganizationContactById(response.data))
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteOrganizationContactByIdHandler(
	organizationId: string,
	contactId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/contacts/${contactId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					contactId
				}
			})
			dispatch(deleteOrganizationContactById(contactId))
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getFacultyByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFaculty[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFaculty[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/faculty`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function createFacultyByIdHandler(
	organizationId: string,
	name: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFaculty> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFaculty>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/faculty`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				},
				data: {
					organizationId,
					name
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteFacultyByIdHandler(
	organizationId: string,
	facultyId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/faculty/${facultyId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					facultyId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getAllGroupsByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFacultyGroup[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFacultyGroup[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/groups`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getFacultyGroupsByIdHandler(
	organizationId: string,
	facultyId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFacultyGroup[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFacultyGroup[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/faculty/${facultyId}/groups`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					facultyId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function createFacultyGroupsByIdHandler(
	organizationId: string,
	facultyId: string,
	name: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFacultyGroup> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFacultyGroup>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/faculty/${facultyId}/groups`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					facultyId
				},
				data: {
					organizationId,
					facultyId,
					name
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteFacultyGroupsByIdHandler(
	organizationId: string,
	facultyId: string,
	groupId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/faculty/${facultyId}/groups/${groupId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					facultyId,
					groupId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getGradesByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IGrade[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IGrade[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/grades`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function createGradesByIdHandler(
	organizationId: string,
	name: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IGrade> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IGrade>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/grades`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId
				},
				data: {
					organizationId,
					name
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteGradesByIdHandler(
	organizationId: string,
	gradeId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/organizations/${organizationId}/grades/${gradeId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: organizationId,
					gradeId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

// PUBLIC

export function getFacultyPublicByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFaculty[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFaculty[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/public/${organizationId}/faculty`,
				params: {
					id: organizationId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getFacultyGroupsPublicByIdHandler(
	organizationId: string,
	facultyId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFacultyGroup[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IFacultyGroup[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/public/${organizationId}/faculty/${facultyId}/groups`,
				params: {
					id: organizationId,
					facultyId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getGradesPublicByIdHandler(
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IGrade[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IGrade[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/public/${organizationId}/grades`,
				params: {
					id: organizationId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getAllCountriesHandler(errorOptions = serviceHandlerErrorOptions): AppThunk<ICountry[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ICountry[]>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/organizations/all-countries`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function getAllNamesHandler(errorOptions = serviceHandlerErrorOptions): AppThunk<IName[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IName[]>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/organizations/names`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}
