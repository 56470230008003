import React, { useState } from 'react'
import { IActivationCodeListResponse } from '../../interfaces/cabinet/activationCode'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { DATE_FORMAT, getDeviceInfo } from '../../helpers/helpers'
import { ACTIVATED } from '../../helpers/roles'

interface IProps {
	item: IActivationCodeListResponse
	userStatus?: string
	onRemove: () => void
	onUnlinkHardware: () => void
	onReactivate: () => void
	isUserCanUnlinkTermlessLicense?: boolean
}

const LicenseItem = ({
	item,
	onRemove,
	userStatus,
	onReactivate,
	onUnlinkHardware,
	isUserCanUnlinkTermlessLicense = false
}: IProps) => {
	const { t } = useTranslation()

	const [isOpened, setIsOpened] = useState(true)

	const classNames = ['form-license-item']

	if (!isOpened) {
		classNames.push('form-license-item--short')
	}

	const { device, platform } = getDeviceInfo(item.hardwareMetaData)

	return (
		<div className={classNames.join(' ')}>
			<div className="form-license-holder">
				<div className="form-license-title" onClick={() => setIsOpened(!isOpened)}>
					{item.productName}
				</div>
				<div className="form-license-params">
					<ul className="list">
						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldVersion')}:</div>
							<div className="list-item-value">{item.versionName}</div>
						</li>
						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldTime')}:</div>
							<div className="list-item-value">
								{item.termless ? (
									<>{t('pages:users:termless')}</>
								) : (
									<>
										{item.dateFrom && item.dateTo && (
											<>{`${dayjs(item.dateFrom).format(DATE_FORMAT)} - ${dayjs(item.dateTo).format(DATE_FORMAT)}`}</>
										)}
										{item.dateFrom && !item.dateTo && (
											<>
												{t('common:from')}
												{dayjs(item.dateFrom).format(DATE_FORMAT)}
											</>
										)}
										{!item.dateFrom && item.dateTo && (
											<>
												{t('common:to')}
												{dayjs(item.dateTo).format(DATE_FORMAT)}
											</>
										)}
										{!item.dateFrom && !item.dateTo && '-'}
									</>
								)}
							</div>
						</li>

						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldStatus')}:</div>
							<div className="list-item-value">
								{item.licenseActivationDate || userStatus === ACTIVATED ? (
									<>
										{item.licenseActivationDate
											? t('pages:users:licenseFieldStatusActivated', {
													date: dayjs(item.licenseActivationDate).format(DATE_FORMAT)
											  })
											: t('pages:users:licenseFieldStatusActivatedUser')}
									</>
								) : (
									t('pages:users:licenseFieldStatusNotActivated')
								)}
							</div>
						</li>

						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldCode')}:</div>
							<div className="list-item-value">
								<small>{item.code || '-'}</small>
							</div>
						</li>

						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldDevice')}:</div>
							<div className="list-item-value">
								<div className="user-license-desc-device">
									<span>
										{item.hardwareMetaData ? (
											<>
												{device && platform ? (
													<>
														{device}
														<br />
														{platform}
													</>
												) : (
													item.hardwareMetaData
												)}
											</>
										) : (
											'-'
										)}
									</span>

									{((isUserCanUnlinkTermlessLicense && item.licenseActivationDate) ||
										(!isUserCanUnlinkTermlessLicense && item.licenseActivationDate && !item.termless)) && (
										<span>
											<button className="button-link" type="button" onClick={onUnlinkHardware}>
												{t('common:actionUnlink')}
											</button>
										</span>
									)}
								</div>
							</div>
						</li>
					</ul>
					<div className="form-license-controls">
						{!item.licenseActivationDate && (
							<button className="button-link" type="button" onClick={onReactivate}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.01">
									<path d="M13.649 2.351a8.006 8.006 0 102.081 7.655h-2.081A6 6 0 117.995 2a5.919 5.919 0 014.223 1.781L8.996 7h7V0z" />
								</svg>
								<span>{t('pages:users:licenseReactivate')}</span>
							</button>
						)}

						{/*<button className="button-link" type="submit">*/}
						{/*	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">*/}
						{/*		<path d="M8 0a8 8 0 108 8 8 8 0 00-8-8zm0 14.5A6.5 6.5 0 1114.5 8 6.5 6.5 0 018 14.5zM10.5 4L8 6.5 5.5 4 4 5.5 6.5 8 4 10.5 5.5 12 8 9.5l2.5 2.5 1.5-1.5L9.5 8 12 5.5z" />*/}
						{/*	</svg>*/}
						{/*	<span>Отменить активацию</span>*/}
						{/*</button>*/}
					</div>
				</div>
				<div className="form-license-toggler" onClick={() => setIsOpened(!isOpened)} />
			</div>

			<div className="form-license-delete">
				<button className="button-link" type="button" onClick={onRemove}>
					{t('common:actionRemove')}
				</button>
			</div>
		</div>
	)
}

export default LicenseItem
