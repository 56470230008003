import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { REGEX_V4 } from '../../../helpers/helpers'
import Error from '../../Error/Error'
import { userConfirmEmailHandler } from '../../../services/passport/user'
import { logoutHandler } from '../../../services/passport/auth'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'

const Confirm = ({ confirmEmail, logout, toggleLoader }: ConnectedProps<typeof connector>) => {
	const { code } = useParams<{ code: string }>()
	const history = useHistory()

	useEffect(() => {
		if (code.match(new RegExp(REGEX_V4))) {
			toggleLoader(true)

			logout()

			try {
				confirmEmail(code).then(response => {
					toggleLoader(false)

					if (response?.status === 200) {
						history.replace('/', {
							from: {
								status: 'EmailConfirmSuccess',
								pathname: '/'
							}
						})
					} else {
						history.replace('/', {
							from: {
								status: 'EmailConfirmFail',
								pathname: '/'
							}
						})
					}
				})
			} finally {
				toggleLoader(false)
			}
		}
	}, [code, confirmEmail, history, logout, toggleLoader])

	return <Error />
}

const mapDispatch = {
	confirmEmail: userConfirmEmailHandler,
	logout: logoutHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Confirm)
