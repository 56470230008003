import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISupportAttachWithData } from '../../interfaces/support/common'
import Lightbox from 'react-image-lightbox'

interface IProps {
	attaches: Array<ISupportAttachWithData>
}

const Attaches = ({ attaches }: IProps) => {
	const { t } = useTranslation()

	const [photoIndex, setPhotoIndex] = useState(0)
	const [isOpen, setIsOpen] = useState(false)
	const [images, setImages] = useState<string[]>([])

	useEffect(() => {
		setImages(attaches.map(img => `data:${img.mimeType};base64, ${img.content}`))
	}, [attaches])

	return (
		<>
			<div className="list-item-label">{t('pages:support:attaches')}</div>
			<div className="list-item-value">
				<div className="support-gallery">
					{images.map((img, idx) => {
						return (
							<div className="support-gallery-item" key={idx}>
								<button
									onClick={() => {
										setPhotoIndex(idx)
										setIsOpen(true)
									}}
								>
									<img src={img} alt="" />
								</button>
							</div>
						)
					})}
				</div>
			</div>

			{isOpen && (
				// @ts-ignore
				<Lightbox
					mainSrc={images[photoIndex]}
					nextSrc={images.length === 1 ? undefined : images[(photoIndex + 1) % images.length]}
					prevSrc={images.length === 1 ? undefined : images[(photoIndex + images.length - 1) % images.length]}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
					onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
				/>
			)}
		</>
	)
}

export default Attaches
