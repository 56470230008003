import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	ILicensePackageRequest,
	ILicensePackageRequestAmountAPIRequest,
	ILicensePackageRequestAmountAPIResponse,
	ILicensePackageRequestAPIRequest,
	ILicensePackageRequestCreate,
	ILicensePackageRequestUpdate
} from '../../interfaces/cabinet/licensePackageRequest'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'
import { getLicencePackageRequestCountGet } from '../../store/reducers/cabinet/licensePackageRequest.actions'

export function licensePackageRequestGetAllHandler(
	params: ILicensePackageRequestAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ILicensePackageRequest[]>> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<APIResponse<ILicensePackageRequest[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/licensePackageRequest`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createLicensePackageRequestHandler(
	data: ILicensePackageRequestCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackageRequest> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ILicensePackageRequest>({
				method: 'POST',
				url: `${API_CABINET_PATH}/licensePackageRequest`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateLicensePackageRequestByIdHandler(
	id: string,
	data: ILicensePackageRequestUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackageRequest> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ILicensePackageRequest>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/licensePackageRequest/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function deleteLicensePackageRequestByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			return await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/licensePackageRequest/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getNewAmountLicensePackageRequestHandler(
	params: ILicensePackageRequestAmountAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackageRequestAmountAPIResponse> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ILicensePackageRequestAmountAPIResponse>({
				method: 'GET',
				url: `${API_CABINET_PATH}/licensePackageRequest/amount-new`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})

			if (response?.status === 200) {
				dispatch(getLicencePackageRequestCountGet(response?.data?.requestsAmount || 0))
			}

			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
