import React, { useEffect, useState } from 'react'
import { RootState } from '../../../../store/reducers/rootReducer'
import {
	createSupportRequestEmployeeAttachHandler,
	createSupportRequestEmployeeCommentHandler,
	deleteSupportRequestEmployeeByIdHandler,
	deleteSupportRequestEmployeeCommentByIdHandler,
	getSupportRequestEmployeeAttachByIdHandler,
	getSupportRequestEmployeeByIdHandler,
	updateSupportRequestEmployeeByIdHandler,
	updateSupportRequestEmployeeCommentByIdHandler
} from '../../../../services/support/supportRequestEmployee'
import { requestTopicGetAllHandler } from '../../../../services/support/requestTopic'
import { modalShowHandler } from '../../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { toggleLoaderHandler } from '../../../../store/actionCreators/UI/loader'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { TStatus } from '../../../../interfaces/helpers'
import {
	ISupportRequestEmployee,
	ISupportRequestEmployeeUpdate
} from '../../../../interfaces/support/supportRequestEmployee'
import { IRequestTopic } from '../../../../interfaces/support/requestTopic'
import { useForm } from 'react-hook-form'
import { ICommentsFormInputs } from '../../../../interfaces/UI/support/commentsForm'
import {
	ISupportAttachWithData,
	ISupportComment,
	ISupportCommentCreate,
	ISupportCommentUpdate
} from '../../../../interfaces/support/common'
import dayjs from 'dayjs'
import {
	DATE_FORMAT_API,
	DATE_FORMAT_FULL,
	fileToBase64,
	formatString,
	getStatusName,
	INFINITE_NUMBER,
	REGEX_V4,
	SOLVED,
	stringToLocalization
} from '../../../../helpers/helpers'
import { sleep } from '../../../../helpers/api'
import ModalEditComment from '../../../../components/Support/ModalEditComment'
import Loading from '../../../../components/UI/Loading/Loading'
import Error from '../../../Error/Error'
import Pagetitle from '../../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../../components/UI/Button/Button'
import Attaches from '../../../../components/Support/Attaches'
import Comments from '../../../../components/Support/Comments'
import CommentsForm from '../../../../components/Support/CommentsForm'
import ModalEditTicket from '../ModalEditTicket'

const Ticket = ({
	user,
	getTopics,
	getTicket,
	updateTicket,
	removeTicket,
	createComment,
	updateComment,
	removeComment,
	createAttach,
	getAttach,
	showModal,
	showModalConfirm,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { ticketId } = useParams<{ ticketId: string }>()
	const history = useHistory()
	const [status, setStatus] = useState<TStatus>('loading')
	const [attachesStatus, setAttachesStatus] = useState<TStatus>('empty')
	const [ticket, setTicket] = useState<ISupportRequestEmployee>()
	const [topics, setTopics] = useState<IRequestTopic[]>([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [images, setImages] = useState<ISupportAttachWithData[]>([])

	const methods = useForm<ICommentsFormInputs>({
		defaultValues: {
			message: '',
			attaches: []
		}
	})

	const onSubmit = async (data: ICommentsFormInputs) => {
		toggleLoader(true)
		setIsSubmitting(true)

		try {
			const comment: ISupportCommentCreate = {
				fromDate: dayjs().format(DATE_FORMAT_API),
				ownerId: user.id,
				message: data.message,
				ownerFirstName: user.firstName,
				ownerLastName: user.lastName,
				ownerPatronymic: user.patronymic || ''
			}

			const attachesRequest = []
			const attaches = []

			if (data.attaches && data.attaches.length > 0) {
				for (const item of data.attaches) {
					const file = await fileToBase64(item)

					const data = {
						fileName: item.name,
						content: file,
						mimeType: item.type
					}

					attachesRequest.push(await sleep(), await createAttach(ticketId, data))
					attaches.push(data)
				}
			}

			const response = await createComment(ticketId, comment)

			if (response?.status === 200) {
				if (ticket) {
					const comments: ISupportComment[] = [
						...ticket.comments,
						{
							id: response.data.id,
							...comment
						}
					]

					setTicket({
						...ticket,
						comments
					})
				}

				await Promise.all(attachesRequest)
				setImages([...images, ...attaches])
			}
		} finally {
			methods.reset()
			setIsSubmitting(false)
			toggleLoader(false)
		}
	}

	const updateTicketHandler = () => {
		showModal({
			type: 'middle',
			Component: ModalEditTicket,
			ComponentProps: {
				topics,
				ticket,
				onConfirm: async (data: ISupportRequestEmployeeUpdate) => {
					toggleLoader(true)

					try {
						const response = await updateTicket(ticketId, data)

						if (response?.status === 200) {
							setTicket({
								...response.data,
								comments: ticket?.comments || []
							})
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},

				onRemove: async () => {
					toggleLoader(true)

					try {
						const response = await removeTicket(ticketId)

						if (response?.status === 200) {
							toggleLoader(false)
							history.replace('/support')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateCommentHandler = (item: ISupportComment) => {
		showModal({
			type: 'large-form',
			Component: ModalEditComment,
			ComponentProps: {
				message: item.message,
				onConfirm: async (message: string) => {
					toggleLoader(true)

					try {
						const comment: ISupportCommentUpdate = {
							...item,
							message
						}

						const response = await updateComment(ticketId, item.id, comment)

						if (response?.status === 200) {
							if (ticket) {
								const updatedComments = [...ticket.comments]
								const index = updatedComments.findIndex(el => el.id === item.id)
								updatedComments[index] = comment

								setTicket({
									...ticket,
									comments: updatedComments
								})
							}
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const removeCommentHandler = (item: ISupportComment) => {
		showModalConfirm({
			type: 'remove',
			questionText: t('pages:support:commentModalRemove'),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await removeComment(ticketId, item.id)

					if (response?.status === 200) {
						if (ticket) {
							const updatedComments = ticket.comments.filter(el => el.id !== item.id)

							setTicket({
								...ticket,
								comments: updatedComments
							})
						}
					}

					return response
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	useEffect(() => {
		if (!ticketId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else {
			const fetchData = async () => {
				try {
					const responseTicket = await getTicket(ticketId)
					const responseTopics = await getTopics({
						topicSortField: 'NAME',
						desc: false,
						pageNo: 0,
						pageSize: INFINITE_NUMBER
					})

					if (responseTopics?.status === 200) {
						setTopics(responseTopics.data.data)
					}

					if (responseTicket?.status === 200) {
						setStatus('ready')

						const { attaches } = responseTicket.data

						setTicket(responseTicket.data)

						if (attaches) {
							setAttachesStatus('loading')

							const requestAttaches = []

							for (let i = 0; i < attaches.length; i++) {
								requestAttaches.push(await sleep(), await getAttach(attaches[i].id))
							}

							const responseAttaches = await Promise.all(requestAttaches)

							const imagesFiles: Array<Blob> = responseAttaches
								.filter((res: any) => res && res.status === 200)
								.map((res, idx) => {
									// const file = new File([res.data], attaches[idx].fileName, {
									// 	type: res.data.type
									// })
									//
									// return file

									return new Blob([res.data], { type: res.data.type })
								})

							if (!imagesFiles) {
								setAttachesStatus('empty')
							}

							if (imagesFiles) {
								const imagesData: ISupportAttachWithData[] = []

								for (let i = 0; i < imagesFiles.length; i++) {
									const content = await fileToBase64(imagesFiles[i])

									imagesData.push({
										mimeType: imagesFiles[i].type,
										content
									})
								}

								if (imagesData) {
									setImages(imagesData)
									setAttachesStatus('ready')
								} else {
									setAttachesStatus('empty')
								}
							}
						}
					}
				} catch (e) {
					setStatus('error')
				}
			}

			fetchData()
		}
	}, [ticketId, getAttach, getTicket, getTopics])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			{ticket && (
				<>
					<Pagetitle title={t('pages:support:titleTicket')} />

					<div className="support-heading">
						<div className="support-heading-status">
							<span className={`task-status task-status--${ticket.state.toLowerCase()}`}>
								{t(getStatusName(ticket.state))}
							</span>
						</div>

						<div className="support-heading-new">
							<Button type={'primary'} htmlType={'button'} onClick={updateTicketHandler}>
								{t('pages:support:editTicket')}
							</Button>
						</div>
					</div>

					{/*<div className="support-info">*/}
					{/*	<ul className="list">*/}
					{/*		<li className="list-item">*/}
					{/*			{t('pages:support:priority')}: <div className="low-priority">{t(getPriorityName(ticket.priority))}</div>*/}
					{/*		</li>*/}
					{/*		<li className="list-item">*/}
					{/*			{t('pages:support:responsible')}: <span>{ticket.employeeName || '-'}</span>*/}
					{/*		</li>*/}
					{/*		<li className="list-item">*/}
					{/*			{t('pages:support:changed')}: <span>???</span>*/}
					{/*		</li>*/}
					{/*		<li className="list-item">*/}
					{/*			{t('pages:support:changedBy')}: <span>???</span>*/}
					{/*		</li>*/}
					{/*	</ul>*/}
					{/*</div>*/}

					<h4 className="support-title">
						#{ticket.num}. {ticket.subject}
					</h4>

					<div className="support-data">
						<ul className="list list--row">
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:createDate')}</div>
								<div className="list-item-value">{dayjs(ticket.fromDate).format(DATE_FORMAT_FULL)}</div>
							</li>
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:owner')}</div>
								<div className="list-item-value">
									{ticket.ownerName || '-'} {ticket.clientName && `(${ticket.clientName})`}
								</div>
							</li>
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:phone')}</div>
								<div className="list-item-value">{ticket.ownerPhone || '-'}</div>
							</li>
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:email')}</div>
								<div className="list-item-value">{ticket.ownerMail || '-'}</div>
							</li>
						</ul>
						<ul className="list">
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:organization')}</div>
								<div className="list-item-value">{ticket.clientName || '-'}</div>
							</li>
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:topic')}</div>
								<div className="list-item-value">{t(`common:topic_${stringToLocalization(ticket.topic.name)}`)}</div>
							</li>
							<li className="list-item">
								<div className="list-item-label">{t('pages:support:message')}</div>
								<div className="list-item-value" dangerouslySetInnerHTML={{ __html: formatString(ticket.message) }} />
							</li>

							{attachesStatus === 'loading' && (
								<li className="list-item">
									<div className="list-item-label">{t('pages:support:attaches')}</div>
									{t('common:loading')}
								</li>
							)}

							{images.length > 0 && (
								<li className="list-item">
									<Attaches attaches={images} />
								</li>
							)}
						</ul>
					</div>

					<div className="support-comments">
						<Comments
							comments={ticket.comments}
							user={user}
							onRemove={removeCommentHandler}
							onUpdate={updateCommentHandler}
						/>

						{ticket.state !== SOLVED && (
							<CommentsForm onSubmit={onSubmit} methods={methods} isSubmitting={isSubmitting} />
						)}
					</div>
				</>
			)}
		</>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const mapDispatch = {
	getTicket: getSupportRequestEmployeeByIdHandler,
	updateTicket: updateSupportRequestEmployeeByIdHandler,
	removeTicket: deleteSupportRequestEmployeeByIdHandler,

	createComment: createSupportRequestEmployeeCommentHandler,
	updateComment: updateSupportRequestEmployeeCommentByIdHandler,
	removeComment: deleteSupportRequestEmployeeCommentByIdHandler,
	getTopics: requestTopicGetAllHandler,

	createAttach: createSupportRequestEmployeeAttachHandler,
	getAttach: getSupportRequestEmployeeAttachByIdHandler,

	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Ticket)
