import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'
import {
	ConnectFeatureWithVersionResponse,
	IFeature,
	IFeatureAPIRequest,
	IFeatureCreate,
	IFeatureUpdate
} from '../../interfaces/cabinet/features'

export function featureGetAllHandler(
	params: IFeatureAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFeature[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IFeature[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/feature`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createFeatureHandler(
	data: IFeatureCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFeature> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IFeature>({
				method: 'POST',
				url: `${API_CABINET_PATH}/feature`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateFeatureHandler(
	id: string,
	data: IFeatureUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFeature> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IFeature>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/feature/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function deleteFeatureByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			return await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/feature/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function connectFeatureWithVersionHandler(
	id: string,
	data: string[],
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ConnectFeatureWithVersionResponse[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ConnectFeatureWithVersionResponse[]>({
				method: 'POST',
				url: `${API_CABINET_PATH}/feature/version/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getFeaturesByVersionHandler(
	versionId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IFeature[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<IFeature[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/feature/version/${versionId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
