import { IApplyResetPassword } from '../../interfaces/passport/auth'
import { AppThunk } from '../../interfaces/helpers'
import axios from 'axios'
import { API_PASSPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { applyResetPassword, initResetPassword } from '../../store/actionCreators/passport/recovery'
import { serviceHandlerErrorOptions } from '../helpers'

export function initResetPasswordHandler(email: string, errorOptions = serviceHandlerErrorOptions): AppThunk<string> {
	return async dispatch => {
		try {
			const response = await axios.request<string>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/public/initResetPassword`,
				auth: {
					username: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
					password: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`
				},
				data: new URLSearchParams({
					email
				})
			})
			localStorage.setItem('resetPasswordDate', response.data)
			localStorage.setItem('resetPasswordEmail', email)
			dispatch(
				initResetPassword({
					date: response.data,
					email: email
				})
			)
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function applyResetPasswordHandler(
	data: IApplyResetPassword,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<boolean> {
	return async dispatch => {
		try {
			const response = await axios.request<boolean>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/public/applyResetPassword`,
				auth: {
					username: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
					password: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`
				},
				data
			})
			localStorage.removeItem('resetPasswordDate')
			localStorage.removeItem('resetPasswordEmail')
			dispatch(applyResetPassword())
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}

export function initResetPasswordByAdminHandler(
	email: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<string> {
	return async dispatch => {
		try {
			const response = await axios.request<string>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/public/initResetPassword`,
				auth: {
					username: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
					password: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`
				},
				data: new URLSearchParams({
					email
				})
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}
