import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../interfaces/helpers'
import { getLocalizationFileContent, getLocalizationFilePath, jsonToHtml } from '../../../helpers/helpers'
import Loading from '../../../components/UI/Loading/Loading'

const Policy = () => {
	const { i18n } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [content, setContent] = useState('')

	useEffect(() => {
		getLocalizationFileContent(getLocalizationFilePath().licenseAgreementNew, i18n.languages[0])
			.then(response => {
				if (response?.status === 200) {
					setContent(jsonToHtml(response.data))
				}
			})
			.finally(() => {
				setStatus('ready')
			})
	}, [i18n.languages])

	if (status === 'loading') return <Loading />

	return <div dangerouslySetInnerHTML={{ __html: content }} />
}

export default Policy
