import React, { useEffect, useMemo, useState } from 'react'
import Button from '../UI/Button/Button'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
	getLocaleName,
	isRuRegion,
	LOCALES,
	MAX_ORGANIZATION_CONTACTS,
	REGEX_EMAIL,
	REGEX_INN,
	REGEX_PHONE
} from '../../helpers/helpers'
import { modalConfirmShowHandler } from '../../store/actionCreators/UI/modalConfirm'
import { notificationAddHandler } from '../../store/actionCreators/UI/notification'
import { connect, ConnectedProps } from 'react-redux'
import Input from '../UI/Input/Input'
import Textarea from '../UI/Textarea/Textarea'
import {
	IContact,
	IContactCreate,
	IContactUpdate,
	ICountry,
	IMainContact,
	IMainContactUpdate,
	IOrganization,
	IOrganizationUpdate
} from '../../interfaces/passport/organizations'
import { useTranslation } from 'react-i18next'
import omit from 'lodash/omit'
import { CLOUD_ADMIN } from '../../helpers/roles'
import { IUser } from '../../interfaces/passport/user'
import Select from 'react-select'
import { selectProps } from '../UI/Select/helpers'
import { getAllCountriesHandler } from '../../services/passport/organization'
import { ORGANIZATION_PROFILES, ORGANIZATION_REGIONS } from '../../helpers/organization'
import Loading from '../UI/Loading/Loading'

export interface IOrganizationUpdateCustom {
	organization: IOrganizationUpdate
	mainContact: IMainContactUpdate
	newContacts: IContactCreate[]
	updatedContacts: IContactUpdate[]
	removedContacts: string[]
}

type TProps = ConnectedProps<typeof connector> & {
	hideModal: () => void
	user: IUser
	organization: IOrganization
	onConfirm: (data: IOrganizationUpdateCustom) => Promise<any>
}

interface IFormFields extends Omit<IOrganizationUpdate, 'country'> {
	mainContact: IMainContact
	contacts: Array<IContact>
	country: string
}

const ModalEditOrganizationInfo = ({
	hideModal,
	user,
	organization,
	onConfirm,
	showModalConfirm,
	notificationAdd,
	getCountries
}: TProps) => {
	const { t } = useTranslation()
	const {
		register,
		errors,
		handleSubmit,
		watch,
		formState: { isDirty },
		control
	} = useForm<IFormFields>({
		defaultValues: {
			fullName: organization.fullName,
			name: organization.name,
			inn: organization.inn,
			locale: organization.locale,
			mainContact: organization.mainContact,
			contacts: organization.contacts,
			profile: organization.profile || '',
			region: organization.region || '',
			country: organization.country?.id || ''
		}
	})
	const {
		fields: contacts,
		append,
		remove
	} = useFieldArray<IContact>({
		control,
		name: 'contacts'
	})

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [countries, setCountries] = useState<ICountry[]>()

	const countryOptions = useMemo(
		() =>
			countries?.map(item => ({
				label: item.name,
				value: item.id
			})) || [],
		[countries]
	)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		try {
			const country = countries?.find(c => c.id === data.country)

			const contacts = (data.contacts && [...data.contacts]) || []

			const currentIds = organization.contacts ? organization.contacts.map(el => el.id) : []
			const formIds = contacts ? contacts.map(el => el.id) : []
			const newIds = formIds.filter(id => !currentIds.includes(id)) || []
			const removedIds = currentIds.filter(id => !formIds.includes(id)) || []

			const newContacts = contacts ? contacts.filter(el => newIds.includes(el.id)).map(el => omit(el, 'id')) : []

			const updatedContacts = contacts
				? contacts.filter(el => el.id && !newIds.includes(el.id) && !removedIds.includes(el.id))
				: []

			const updatedOrganization =
				user.role === CLOUD_ADMIN
					? {
							fullName: data.fullName,
							name: data.name,
							inn: data.inn === '' ? null : data.inn,
							locale: data.locale,
							profile: data.profile,
							region: data.region,
							country: country!
					  }
					: {
							fullName: organization.fullName,
							name: organization.name,
							inn: organization.inn === '' ? null : organization.inn,
							locale: organization.locale,
							profile: organization.profile,
							region: organization.region,
							country: organization.country
					  }

			const response = await onConfirm({
				organization: updatedOrganization,
				mainContact: data.mainContact,
				newContacts,
				updatedContacts,
				removedContacts: removedIds
			})

			const errors = response.filter((res: any) => res.status !== 200) || []

			if (errors.length) {
				notificationAdd(t('pages:organizations:notificationUpdateError'), 'warning')
			} else {
				notificationAdd(t('pages:organizations:notificationUpdate'), 'info')
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const addContactHandle = () => {
		if (contacts.length < MAX_ORGANIZATION_CONTACTS) {
			append({
				name: '',
				jobName: '',
				phone: '',
				email: ''
			})
		} else {
			notificationAdd(t('pages:organizations:notificationContactsLimit'), 'info')
		}
	}

	useEffect(() => {
		getCountries().then(response => {
			if (response?.status === 200) {
				setCountries(response.data)
			}
		})
	}, [getCountries])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:organizations:modalUpdateText')}</p>

			{!countries ? (
				<Loading />
			) : (
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					<div className="form-item">
						{user.role === CLOUD_ADMIN ? (
							<Textarea
								currentValue={watch('fullName')}
								name={'fullName'}
								placeholder={t('pages:organizations:fieldFullName')}
								isDisabled={isSubmitting}
								isError={!!errors.fullName}
								isRequired={true}
								reference={register({
									required: true
								})}
								errors={errors.fullName}
							/>
						) : (
							<>
								<div className="form-info-label">{t('pages:organizations:fieldFullName')}</div>
								<div className="form-info-value">{organization.fullName || '-'}</div>
							</>
						)}
					</div>

					<div className="form-item">
						{user.role === CLOUD_ADMIN ? (
							<Input
								currentValue={watch('name')}
								type={'text'}
								name={'name'}
								placeholder={t('pages:organizations:fieldName')}
								isDisabled={isSubmitting}
								isError={!!errors.name}
								isRequired={true}
								reference={register({
									required: true
								})}
								errors={errors.name}
							/>
						) : (
							<>
								<div className="form-info-label">{t('pages:organizations:fieldName')}</div>
								<div className="form-info-value">{organization.name || '-'}</div>
							</>
						)}
					</div>

					{user.role === CLOUD_ADMIN ? (
						<div className="form-item">
							<Controller
								render={({ onChange, name, value }) => (
									<Select
										name={name}
										options={ORGANIZATION_PROFILES}
										defaultValue={ORGANIZATION_PROFILES.find(p => p.value === value)}
										placeholder={t('pages:organizations:fieldProfile')}
										isDisabled={isSubmitting}
										isRequired={true}
										isError={!!errors.profile}
										errors={errors.profile}
										onChange={option => option && onChange(option.value)}
										{...selectProps}
									/>
								)}
								name={'profile'}
								control={control}
								rules={{
									required: true
								}}
							/>
						</div>
					) : (
						<>
							{isRuRegion() ? (
								<div className="form-item">
									<div className="form-info-label">{t('pages:organizations:fieldProfile')}</div>
									<div className="form-info-value">
										{ORGANIZATION_PROFILES.find(p => p.value === organization.profile)?.label || '-'}
									</div>
								</div>
							) : null}
						</>
					)}

					{user.role === CLOUD_ADMIN ? (
						<div className="form-item">
							<Controller
								render={({ onChange, name, value }) => (
									<Select
										name={name}
										options={ORGANIZATION_REGIONS}
										defaultValue={ORGANIZATION_REGIONS.find(r => r.value === value)}
										placeholder={t('pages:organizations:fieldRegion')}
										isDisabled={isSubmitting}
										isRequired={true}
										isError={!!errors.region}
										errors={errors.region}
										onChange={option => option && onChange(option.value)}
										{...selectProps}
									/>
								)}
								name={'region'}
								control={control}
								rules={{
									required: true
								}}
							/>
						</div>
					) : (
						<>
							{isRuRegion() ? (
								<div className="form-item">
									<div className="form-info-label">{t('pages:organizations:fieldRegion')}</div>
									<div className="form-info-value">
										{ORGANIZATION_REGIONS.find(r => r.value === organization.region)?.label || '-'}
									</div>
								</div>
							) : null}
						</>
					)}

					{user.role === CLOUD_ADMIN ? (
						<div className="form-item">
							<Controller
								render={({ onChange, name, value }) => (
									<Select
										name={name}
										options={countryOptions}
										defaultValue={countryOptions.find(c => c.value === value)}
										placeholder={t('pages:organizations:fieldCountry')}
										isDisabled={isSubmitting}
										isRequired={true}
										isError={!!errors.country}
										errors={errors.country}
										onChange={option => option && onChange(option.value)}
										{...selectProps}
									/>
								)}
								name={'country'}
								control={control}
								rules={{
									required: true
								}}
							/>
						</div>
					) : (
						<>
							{isRuRegion() ? (
								<div className="form-item">
									<div className="form-info-label">{t('pages:organizations:fieldCountry')}</div>
									<div className="form-info-value">{organization.country?.name || '-'}</div>
								</div>
							) : null}
						</>
					)}

					<div className="form-item">
						{user.role === CLOUD_ADMIN ? (
							<Input
								currentValue={watch('inn') || ''}
								type={'text'}
								name={'inn'}
								placeholder={t('pages:organizations:fieldInn')}
								isError={!!errors.inn}
								isDisabled={isSubmitting}
								reference={register({
									pattern: REGEX_INN,
									minLength: 10,
									maxLength: 12
								})}
								rules={{
									minLength: 10,
									maxLength: 12
								}}
								errors={errors.inn}
							/>
						) : (
							<>
								<div className="form-info-label">{t('pages:organizations:fieldInn')}</div>
								<div className="form-info-value">{organization.inn || '-'}</div>
							</>
						)}
					</div>

					{user.role === CLOUD_ADMIN && (
						<div className="form-item">
							<Controller
								render={({ onChange, name }) => (
									<Select
										name={name}
										options={LOCALES.map(item => {
											return {
												label: t(getLocaleName(item)),
												value: item
											}
										})}
										placeholder={t('pages:organizations:fieldLocale')}
										isDisabled={isSubmitting}
										isRequired={true}
										isError={!!errors.locale}
										errors={errors.locale}
										defaultValue={{
											label: t(getLocaleName(organization.locale)),
											value: organization.locale
										}}
										onChange={option => option && onChange(option.value)}
										{...selectProps}
									/>
								)}
								name={'locale'}
								control={control}
								rules={{
									required: true
								}}
							/>
						</div>
					)}

					<div className="form-group">
						<div className="form-group-title">
							<span>{t('pages:organizations:mainContactTitle')}</span>
						</div>

						<input type="hidden" name={`mainContact.id`} defaultValue={organization.mainContact.id} ref={register()} />

						<div className="form-item">
							<Input
								currentValue={watch('mainContact.name')}
								type={'text'}
								name={'mainContact.name'}
								placeholder={t('pages:organizations:fieldContactName')}
								isDisabled={isSubmitting}
								isError={!!errors.mainContact?.name}
								reference={register}
								errors={errors.mainContact?.name}
							/>
						</div>
						<div className="form-item">
							<Input
								currentValue={watch('mainContact.jobName')}
								type={'text'}
								name={'mainContact.jobName'}
								placeholder={t('pages:organizations:fieldJobName')}
								isDisabled={isSubmitting}
								isError={!!errors.mainContact?.jobName}
								reference={register}
							/>
						</div>
						<div className="form-row">
							<div className="form-col">
								<div className="form-item">
									<Input
										currentValue={watch('mainContact.phone')}
										type={'tel'}
										name={'mainContact.phone'}
										placeholder={t('pages:organizations:fieldPhone')}
										isDisabled={isSubmitting}
										isError={!!errors.mainContact?.phone}
										reference={register({
											maxLength: 255,
											pattern: REGEX_PHONE
										})}
										rules={{
											maxLength: 255
										}}
										errors={errors.mainContact?.phone}
									/>
								</div>
							</div>
							<div className="form-col">
								<div className="form-item">
									<Input
										currentValue={watch('mainContact.email')}
										type={'email'}
										name={'mainContact.email'}
										placeholder={t('pages:organizations:fieldEmail')}
										isDisabled={isSubmitting}
										isError={!!errors.mainContact?.email}
										reference={register({
											minLength: 6,
											maxLength: 255,
											pattern: REGEX_EMAIL
										})}
										rules={{
											minLength: 6,
											maxLength: 255
										}}
										errors={errors.mainContact?.email}
									/>
								</div>
							</div>
						</div>
					</div>

					{contacts.length > 0 &&
						contacts.map((item, idx: number) => {
							return (
								<div key={item.id} className="form-group">
									<div className="form-group-title">
										<span>{t('pages:organizations:contactTitle')}</span>

										<button type="button" onClick={() => remove(idx)}>
											{t('pages:organizations:actionRemove')}
										</button>
									</div>

									<input
										type="hidden"
										name={`contacts[${idx}].id`}
										defaultValue={contacts?.[idx]?.id}
										ref={register()}
									/>

									<div className="form-item">
										<Input
											currentValue={watch(`contacts[${idx}].name`)}
											type={'text'}
											name={`contacts[${idx}].name`}
											placeholder={t('pages:organizations:fieldContactName')}
											isDisabled={isSubmitting}
											isError={!!errors?.contacts?.[idx]?.name}
											isRequired={true}
											reference={register({
												required: true
											})}
											defaultValue={contacts?.[idx]?.name}
											errors={errors?.contacts?.[idx]?.name}
										/>
									</div>

									<div className="form-item">
										<Input
											currentValue={watch(`contacts[${idx}].jobName`)}
											type={'text'}
											name={`contacts[${idx}].jobName`}
											placeholder={t('pages:organizations:fieldJobName')}
											isDisabled={isSubmitting}
											isError={!!errors?.contacts?.[idx]?.jobName}
											reference={register}
											defaultValue={contacts?.[idx]?.jobName}
										/>
									</div>

									<div className="form-row">
										<div className="form-col">
											<div className="form-item">
												<Input
													currentValue={watch(`contacts[${idx}].phone`)}
													type={'tel'}
													name={`contacts[${idx}].phone`}
													placeholder={t('pages:organizations:fieldPhone')}
													isDisabled={isSubmitting}
													isError={!!errors?.contacts?.[idx]?.phone}
													reference={register({
														pattern: REGEX_PHONE
													})}
													defaultValue={contacts?.[idx]?.phone}
													errors={errors?.contacts?.[idx]?.phone}
												/>
											</div>
										</div>

										<div className="form-col">
											<div className="form-item">
												<Input
													currentValue={watch(`contacts[${idx}].email`)}
													type={'email'}
													name={`contacts[${idx}].email`}
													placeholder={t('pages:organizations:fieldEmail')}
													isRequired={true}
													isDisabled={isSubmitting}
													isError={!!errors?.contacts?.[idx]?.email}
													reference={register({
														required: true,
														minLength: 6,
														maxLength: 255,
														pattern: REGEX_EMAIL
													})}
													defaultValue={contacts?.[idx]?.email}
													rules={{
														minLength: 6,
														maxLength: 255
													}}
													errors={errors?.contacts?.[idx]?.email}
												/>
											</div>
										</div>
									</div>
								</div>
							)
						})}

					<div className="form-controls form-controls--justify form-controls--fit-buttons">
						<Button
							type={'secondary'}
							htmlType={'button'}
							className={'button--add-group'}
							onClick={addContactHandle}
							isDisabled={contacts.length >= MAX_ORGANIZATION_CONTACTS}
						>
							{t('pages:organizations:actionAdd')}
						</Button>

						<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler}>
							{t('common:actionCancel')}
						</Button>

						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
							{t('common:actionSave')}
						</Button>
					</div>
				</form>
			)}
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler,
	getCountries: getAllCountriesHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditOrganizationInfo)
