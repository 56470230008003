import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import omit from 'lodash/omit'
import { orderBy, uniqBy } from 'lodash'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { connect, ConnectedProps } from 'react-redux'
import {
	IBatchActionsUsersResponse,
	IUploadUserData,
	IUploadUsers,
	IUserAPIRequest,
	IUserExportAPIRequest,
	TUserLicenseTypes
} from '../../../interfaces/passport/user'
import { IPagination, TStatus } from '../../../interfaces/helpers'
import { IFacultyGroup } from '../../../interfaces/passport/organizations'
import { USER } from '../../../helpers/roles'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'
import { DATE_FORMAT, getPlatformName, INFINITE_NUMBER, isRuRegion, PLATFORMS } from '../../../helpers/helpers'
import ModalUploadUsers from '../../../components/User/ModalUploadUsers'
import { sleep } from '../../../helpers/api'
import Loading from '../../../components/UI/Loading/Loading'
import Error from '../../Error/Error'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import SearchField from '../../../components/UI/SearchField/SearchField'
import Button from '../../../components/UI/Button/Button'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import {
	exportUsersHandler,
	getAllUsersHandler,
	lockUsersInOrgByIdsHandler,
	removeFromOrgByIdsHandler,
	uploadUsersHandler,
	uploadUsersStatusHandler
} from '../../../services/passport/user'
import { getAllGroupsByIdHandler } from '../../../services/passport/organization'
import { getActiveLicensePackageWithGroupsHandler } from '../../../services/cabinet/licensePackage'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { modalErrorShowHandler } from '../../../store/actionCreators/UI/modalError'
import { modalLogShowHandler } from '../../../store/actionCreators/UI/modalLog'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import UsersTable, { ITableUser, userLicenseRender, userNameRender } from './UsersTable'
import { getActivationCodeByUserIdsHandler } from '../../../services/cabinet/activationCode'

interface IUsersListProps {
	organizationId: string
	accountId: string
	licensePackageId?: string
	userLicenseType?: TUserLicenseTypes
	title?: string
	parentRoute?: string
	crumbs?: Array<{
		to: string
		title: string
		onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	}>
}

type TUserFilterOptions = 'groupFilter' | 'userLicenseType' | 'platform' | 'isStudent'

const UsersList = ({
	organizationId,
	accountId,
	licensePackageId,
	userLicenseType,
	title,
	parentRoute,
	crumbs,
	loadActiveLicenseGroups,
	getAllFacultyGroups,
	getLicenseInfo,
	getUsers,
	uploadUsers,
	uploadUsersStatus,
	exportUsers,
	lockUsers,
	deleteUsers,
	toggleLoader,
	showModal,
	showModalLog,
	showModalConfirm,
	showModalError,
	notificationAdd
}: IUsersListProps & ConnectedProps<typeof connector>) => {
	const tableRef = useRef<{ reset: () => void }>(null)
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [facultyGroups, setFacultyGroups] = useState<IFacultyGroup[]>([])
	const [isGroupControlsDisabled, setIsGroupControlsDisabled] = useState(true)
	const [groupControlAction, setGroupControlAction] = useState<'lock' | 'remove'>('lock')
	const [table, setTable] = useState<ITableUser[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})
	const [pageSize, setPageSize] = useState<number>(100)
	const [filterUsers, setFilterUsers] = useState<
		Omit<IUserAPIRequest, 'userSortField' | 'pageSize' | 'pageNo' | 'desc'>
	>({
		nameFilter: '',
		roleFilter: USER,
		orgFilter: organizationId,
		licensePackageFilter: licensePackageId,
		userLicenseType
	})

	const selectOperations = [
		{
			label: t('common:actionLock'),
			value: 'lock'
		},
		{
			label: t('common:actionRemove'),
			value: 'remove'
		}
	]

	const licenseTypes = [
		{
			label: t('common:selectDefaultValue'),
			value: ''
		},
		{
			label: t('pages:users:termless'),
			value: 'TERMLESS'
		},
		{
			label: t('pages:users:urgent'),
			value: 'LIMITED'
		},
		{
			label: t('pages:users:absent'),
			value: 'ABSENT'
		}
	]

	const paginationHandle = (pageNo: number) => {
		tableRef.current?.reset()
	}

	const limitHandle: TOnChange = option => {
		if (!option) return

		setPageSize(parseInt(option.value) || INFINITE_NUMBER)
	}

	const onSelectHandle = (items: ITableUser[]) => {
		setTable(items)
		setIsGroupControlsDisabled(items.length === 0)
	}

	const onTextFilterChanged = (value: string) => {
		// TODO: поиск по всем полям

		setFilterUsers({
			...filterUsers,
			nameFilter: value
		})
	}

	const onSelectFilterChanged: (param: TUserFilterOptions, isBool?: boolean) => TOnChange = (param, isBool = false) => {
		return option => {
			if (!option) return

			if (option.value) {
				setFilterUsers({
					...filterUsers,
					[param]: isBool ? option.value === '1' : option.value
				})
			} else {
				setFilterUsers({
					...omit(filterUsers, [param])
				})
			}
		}
	}

	const submitUploadUsers = async (data: IUploadUserData) => {
		toggleLoader(true)

		const handleUploadResponse = async (response: AxiosResponse<IUploadUsers>) => {
			if (response?.status === 200) {
				const { finished, okCount, totalCount, sessionId, results } = response.data

				if (finished && okCount === totalCount) {
					notificationAdd(t('pages:users:notificationUpload'), 'info')
					paginationHandle(0)
				} else {
					if (sessionId) {
						if (!finished) {
							const status = await uploadUsersStatus(sessionId)
							await sleep(2000)
							await handleUploadResponse(status)
						} else {
							const status = await uploadUsersStatus(sessionId, true)

							showModalLog({
								message: t('pages:users:modalLogText'),
								details: status.data.results
							})
						}
					} else {
						showModalLog({
							message: t('pages:users:modalLogText'),
							details: results
						})
					}
				}
			}
		}

		try {
			const response = await uploadUsers({
				organizationId,
				...data
			})
			await handleUploadResponse(response)
		} finally {
			toggleLoader(false)
		}
	}

	const uploadUsersHandle = async () => {
		toggleLoader(true)
		try {
			const licensePackages = await loadActiveLicenseGroups(accountId)
			if (licensePackages.status !== 200) return
			showModal({
				type: 'middle',
				Component: ModalUploadUsers,
				ComponentProps: {
					licensePackages: licensePackages.data,
					onConfirm: submitUploadUsers
				}
			})
		} finally {
			toggleLoader(false)
		}
	}

	const exportUsersHandle = async () => {
		toggleLoader(true)

		try {
			const fileName = `users_${dayjs(new Date()).format(DATE_FORMAT)}.xls`

			const exportParams: IUserExportAPIRequest = {
				...omit(filterUsers, ['pageNo', 'pageSize']),
				fileName
			}

			const response = await exportUsers(exportParams)

			if (response?.status === 200) {
				const file = new Blob([response.data])
				FileSaver.saveAs(file, fileName)
			}
		} finally {
			toggleLoader(false)
		}
	}

	const batchOperationsResponseHandler = (response: AxiosResponse<IBatchActionsUsersResponse[]>) => {
		if (response?.status === 200) {
			const errors = response.data.filter(item => !item.result) || []
			const errorIds: Array<string> = errors.map(item => item.id) || []
			const errorUsers: Array<string> =
				table
					.filter(item => errorIds.includes(item.id))
					.map(item => {
						const errorReason = errors.find(el => el.id === item.id)

						if (errorReason) {
							return `${item.lastName} ${item.firstName} (${errorReason.error})`
						} else {
							return `${item.lastName} ${item.firstName}`
						}
					}) || []

			if (errorUsers.length > 0) {
				showModalError({
					errorDetails: {
						message: t(`pages:users:notificationBatchError`, {
							value: errorUsers.join(', ')
						}),
						id: errorIds.join(', ')
					}
				})
			} else {
				notificationAdd(t(`pages:users:notificationBatch_${groupControlAction}`), 'info')
			}
		}
	}

	const batchOperationsSelectHandler: TOnChange = option => {
		if (option) {
			if (option.value === 'lock') setGroupControlAction('lock')
			if (option.value === 'remove') setGroupControlAction('remove')
		}
	}

	const batchOperationsActionHandler = () => {
		const selectedUsers = table.filter(item => item.selected)
		const selectedIds = selectedUsers.map(item => item.id)
		const selectedTexts = selectedUsers.map(item => `${item.lastName} ${item.firstName}`)

		if (selectedUsers.length) {
			showModalConfirm({
				type: groupControlAction,
				elementText: selectedTexts.join(', '),
				onConfirm: async () => {
					toggleLoader(true)

					try {
						if (groupControlAction === 'lock') {
							const response = await lockUsers(selectedIds, organizationId)
							batchOperationsResponseHandler(response)
						}

						if (groupControlAction === 'remove') {
							const response = await deleteUsers(selectedIds, organizationId)
							batchOperationsResponseHandler(response)
						}
					} finally {
						paginationHandle(0)
						toggleLoader(false)
					}
				}
			})
		}
	}

	useEffect(() => {
		if (!organizationId) return

		setIsGroupControlsDisabled(true)
		setGroupControlAction('lock')
		setStatus('ready')
	}, [organizationId])

	useEffect(() => {
		if (!organizationId) return

		getAllFacultyGroups(organizationId).then(response => {
			if (response.status !== 200) return

			let allGroups = response.data
			allGroups = orderBy(allGroups, 'name')
			allGroups = uniqBy(allGroups, 'id')

			setFacultyGroups(allGroups)
		})
	}, [getAllFacultyGroups, organizationId])

	if (status === 'error') return <Error />
	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={title || t('pages:users:title')} crumbs={crumbs} />

			{!licensePackageId && (
				<div className="heading">
					<div className="heading-section">
						{isRuRegion() ? (
							<p dangerouslySetInnerHTML={{ __html: t('pages:users:intro') }} />
						) : (
							<p dangerouslySetInnerHTML={{ __html: t('pages:users:introAlt') }} />
						)}

						<div className="table-data-links">
							<ul className="list list--links list--links-icons">
								<li className="list-item">
									<button className="button-link" type="button" onClick={exportUsersHandle}>
										<svg xmlns="http://www.w3.org/2000/svg" width="10.706" height="13">
											<path d="M3.059 9.941h4.588V5.353h3.056L5.353 0 .003 5.353h3.059zM.003 11.473h10.7v1.529H.003z" />
										</svg>
										<span>{t('pages:users:usersExport')}</span>
									</button>
								</li>

								<li className="list-item">
									<button className="button-link" type="button" onClick={uploadUsersHandle}>
										<svg xmlns="http://www.w3.org/2000/svg" width="10.706" height="13">
											<path d="M10.703 4.588H7.648V0H3.059v4.588H.003l5.353 5.353zm-10.7 6.885v1.529h10.7v-1.529z" />
										</svg>
										<span>{t('pages:users:usersImport')}</span>
									</button>
								</li>
							</ul>
						</div>
					</div>

					<div className="heading-aside">
						<Link to={`${parentRoute || ''}/users/add`} className="button button--primary">
							{t('pages:users:addUser')}
						</Link>
					</div>
				</div>
			)}

			<div className="filters">
				<div className="filters-item">
					<SearchField onSubmit={onTextFilterChanged} placeholder={t('pages:users:searchField')} />
				</div>

				<div className="filters-item">
					<Select
						name={'role'}
						options={[
							{
								label: t('common:selectDefaultValue'),
								value: ''
							},
							{
								label: t('pages:users:roleStudent'),
								value: '1'
							},
							{
								label: t('pages:users:roleTeacher'),
								value: '0'
							}
						]}
						defaultValue={{
							label: t('common:selectDefaultValue'),
							value: ''
						}}
						placeholder={t('pages:users:role')}
						onChange={onSelectFilterChanged('isStudent', true)}
						{...selectProps}
					/>
				</div>

				<div className="filters-item">
					<Select
						name={'facultyGroup'}
						options={[
							{
								label: t('common:selectDefaultValue'),
								value: ''
							},
							...facultyGroups.map(item => ({
								label: item.name,
								value: item.id
							}))
						]}
						defaultValue={{
							label: t('common:selectDefaultValue'),
							value: ''
						}}
						placeholder={t('pages:users:facultyGroup')}
						onChange={onSelectFilterChanged('groupFilter')}
						{...selectProps}
					/>
				</div>

				<div className="filters-item">
					<Select
						name={'userLicenseType'}
						options={licenseTypes}
						defaultValue={
							userLicenseType
								? licenseTypes.find(t => t.value === userLicenseType)
								: {
										label: t('common:selectDefaultValue'),
										value: ''
								  }
						}
						isDisabled={!!userLicenseType}
						placeholder={t('pages:users:userLicenseType')}
						onChange={onSelectFilterChanged('userLicenseType')}
						{...selectProps}
					/>
				</div>

				<div className="filters-item">
					<Select
						name={'platform'}
						options={[
							{
								label: t('common:selectDefaultValue'),
								value: ''
							},
							...PLATFORMS.map(item => ({
								label: t(getPlatformName(item)),
								value: t(getPlatformName(item))
							})),
							{
								label: t('pages:users:platformAbsent'),
								value: 'Absent'
							}
						]}
						defaultValue={{
							label: t('common:selectDefaultValue'),
							value: ''
						}}
						placeholder={t('pages:users:platform')}
						onChange={onSelectFilterChanged('platform')}
						{...selectProps}
					/>
				</div>
			</div>

			<UsersTable
				ref={tableRef}
				columns={[
					{
						width: '22.3%',
						title: 'pages:users:tableHeadingName',
						fieldName: 'firstName',
						sortName: 'Name',
						mapValue: userNameRender(parentRoute || '')
					},
					{
						width: '15.5%',
						title: 'pages:users:tableHeadingRole',
						fieldName: 'roleName',
						sortName: 'Role'
					},
					{
						width: '13.9%',
						title: 'pages:users:tableHeadingFacultyGroup',
						fieldName: 'groupName',
						sortName: 'FacultyGroup'
					},
					{
						width: '15.5%',
						title: 'pages:users:tableHeadingDate',
						fieldName: 'licenses',
						mapValue: userLicenseRender(t)
					},
					{
						width: '13%',
						title: 'pages:users:tableHeadingPlatform',
						fieldName: 'platforms'
					},
					{
						width: '14.58%',
						title: 'pages:users:tableHeadingStatus',
						fieldName: 'statusName'
					}
				]}
				pageSize={pageSize}
				filter={filterUsers}
				selectable={true}
				getUsers={getUsers}
				getLicenseInfo={getLicenseInfo}
				onSelect={onSelectHandle}
				onLoaded={setPagination}
			>
				<div className="table-footer">
					<div className="table-footer-item">
						<div className="group-actions">
							<div className="group-actions-label">{t('pages:users:batchActions')}</div>

							<div className="group-actions-control">
								<Select
									name={'operation'}
									onChange={batchOperationsSelectHandler}
									options={selectOperations}
									defaultValue={selectOperations[0]}
									placeholder={''}
									isDisabled={isGroupControlsDisabled}
									{...selectProps}
								/>

								<Button
									type={'secondary'}
									htmlType={'button'}
									isDisabled={isGroupControlsDisabled}
									onClick={batchOperationsActionHandler}
								>
									{t('common:actionAccept')}
								</Button>
							</div>
						</div>
					</div>

					<div className="table-footer-item table-footer-item--view">
						<SelectLimit
							total={pagination.totalCount}
							onChange={limitHandle}
							placeholder={t('common:SelectLimitPlaceholder')}
							defaultValue={{
								label: '100',
								value: '100'
							}}
						/>
					</div>
				</div>
			</UsersTable>
		</>
	)
}

const mapDispatch = {
	getUsers: getAllUsersHandler,
	getLicenseInfo: getActivationCodeByUserIdsHandler,
	getAllFacultyGroups: getAllGroupsByIdHandler,
	uploadUsers: uploadUsersHandler,
	uploadUsersStatus: uploadUsersStatusHandler,
	exportUsers: exportUsersHandler,

	lockUsers: lockUsersInOrgByIdsHandler,
	deleteUsers: removeFromOrgByIdsHandler,

	loadActiveLicenseGroups: getActiveLicensePackageWithGroupsHandler,

	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	showModalError: modalErrorShowHandler,
	showModalLog: modalLogShowHandler,
	toggleLoader: toggleLoaderHandler,
	notificationAdd: notificationAddHandler
}

const connector = connect(null, mapDispatch)

export default connector(UsersList)
