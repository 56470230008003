import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from './locales/resources'
import { getAppName, isRuRegion } from './helpers/helpers'

// DatePicker
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'

registerLocale('ru', ru)
registerLocale('en', en)

const locales: Resource = {} as Resource

for (const locale in resources) {
	const key = locale as keyof typeof resources

	if (!isRuRegion() && key === 'ru') {
		continue
	}

	resources[key].common.appName = getAppName()

	locales[key] = resources[key]
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: locales,
		load: 'languageOnly',
		fallbackLng: 'en',
		debug: false,
		ns: ['common'],
		defaultNS: 'common',
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: true
		}
	})
	.then(() => {
		setDefaultLocale(i18n.languages[0])
	})

export default i18n
