import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	ConnectVersionWithBuildsResponse,
	IVersion,
	IVersionAPIRequest,
	IVersionCreate,
	IVersionUpdate
} from '../../interfaces/cabinet/versions'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function versionGetAllHandler(
	params: IVersionAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IVersion[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IVersion[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/version`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function versionGetAllByAccountIdHandler(
	accountId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IVersion[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IVersion[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/version/list`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					accountId
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createVersionHandler(
	data: IVersionCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IVersion> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IVersion>({
				method: 'POST',
				url: `${API_CABINET_PATH}/version`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateVersionByIdHandler(
	id: string,
	data: IVersionUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IVersion> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IVersion>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/version/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function connectVersionWithBuildsHandler(
	id: string,
	data: string[],
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ConnectVersionWithBuildsResponse[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ConnectVersionWithBuildsResponse[]>({
				method: 'POST',
				url: `${API_CABINET_PATH}/build/version/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function deleteVersionByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/version/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getVersionByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk<IVersion> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IVersion>({
				method: 'GET',
				url: `${API_CABINET_PATH}/version/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getVersionNamesByIdsHandler(
	ids: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IVersion[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IVersion[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/version/names/ids`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					ids
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
