import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectProps, TOnChange } from './helpers'
import { isRuRegion } from '../../../helpers/helpers'

const options = [
	{
		value: 'en',
		label: 'English'
	}
]

if (isRuRegion()) {
	options.push({
		value: 'ru',
		label: 'Русский'
	})
}

const SelectLanguage = () => {
	const { t, i18n } = useTranslation()

	const changeHandler: TOnChange = option => {
		if (option) i18n.changeLanguage('' + option.value)
	}

	return (
		<Select
			name={'language'}
			placeholder={t('common:SelectLanguagePlaceholder')}
			options={options}
			onChange={changeHandler}
			defaultValue={options.find(item => item.value === i18n.languages[0]) || options[0]}
			{...selectProps}
		/>
	)
}

export default SelectLanguage
