import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	IHardwareKey,
	IHardwareKeyAPIRequest,
	IHardwareKeyCreate,
	IHardwareKeyUpdate,
	TUploadHardwareKeys
} from '../../interfaces/cabinet/hardwareKeys'
import { setProgress } from '../../store/actionCreators/app'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function hardwareKeyGetAllHandler(
	params: IHardwareKeyAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IHardwareKey[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IHardwareKey[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/hardwareKey`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createHardwareKeyHandler(
	data: IHardwareKeyCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IHardwareKey> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IHardwareKey>({
				method: 'POST',
				url: `${API_CABINET_PATH}/hardwareKey`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateHardwareKeyByIdHandler(
	id: string,
	data: IHardwareKeyUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IHardwareKey> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IHardwareKey>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/hardwareKey/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function deleteHardwareKeyByIdsHandler(ids: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/hardwareKey`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					ids
				}
			})
			return response
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function uploadHardwareKeyHandler(
	data: File[],
	errorOptions = serviceHandlerErrorOptions
): AppThunk<TUploadHardwareKeys> {
	return async (dispatch, getState) => {
		const formData = new FormData()
		formData.append('data', data[0])

		try {
			const response = await axios.request<TUploadHardwareKeys>({
				method: 'POST',
				url: `${API_CABINET_PATH}/hardwareKey/batchImport`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`,
					'Content-Type': `multipart/form-data`
				},
				data: formData,
				onUploadProgress: progress => {
					const value = Math.round((progress.loaded * 100) / progress.total)
					dispatch(setProgress(value))
				}
			})
			dispatch(setProgress(0))
			return response
		} catch (error: any) {
			dispatch(setProgress(0))
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
