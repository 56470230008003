import axios from 'axios'
import { setErrorHandler } from '../../store/actionCreators/error'
import { API_LICENSE_PATH, APIServices } from '../../helpers/api'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'
import { ILicenseFile, ILicenseFileAPIRequest, ILicenseUnlinkResponse } from '../../interfaces/license/license'

export function licenseFileGetAllHandler(
	params: ILicenseFileAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ILicenseFile[]>> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<APIResponse<ILicenseFile[]>>({
				method: 'GET',
				url: `${API_LICENSE_PATH}/license/licenseFile/list`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.LICENSE, errorOptions))
			return error.response
		}
	}
}

export function unlinkLicenseHandler(
	code: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicenseUnlinkResponse> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ILicenseUnlinkResponse>({
				method: 'DELETE',
				url: `${API_LICENSE_PATH}/license`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					activationCode: code
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.LICENSE, errorOptions))
			return error.response
		}
	}
}

export function unlinkLicenseProductHandler(
	productName: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicenseUnlinkResponse> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ILicenseUnlinkResponse>({
				method: 'DELETE',
				url: `${API_LICENSE_PATH}/license/product`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					productName
				}
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.LICENSE, errorOptions))
			return error.response
		}
	}
}

export function downloadLicenseFileHandler(url: string, errorOptions = serviceHandlerErrorOptions): AppThunk<BlobPart> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<BlobPart>({
				method: 'GET',
				url: `${API_LICENSE_PATH}${url}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				responseType: 'blob'
			})
		} catch (error: any) {
			dispatch(setErrorHandler(error, APIServices.LICENSE, errorOptions))
			return error.response
		}
	}
}
